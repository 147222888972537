import { generateId } from 'helpers/AutoId';
import { Roles } from './Roles.Enum';
import { IUserContactData, IUserData } from './User';

export class User {
  public id = '';
  public displayName = '';
  public location = null;
  public displaySurname = '';
  public studentsPreferencesNotes = '';
  public learningPreferences = [];
  public about = '';
  public showCase = [];
  public comments = [];
  public notes = {};
  public email = '';
  public instruments = [];
  public parents = null;
  public emailVerified = false;
  public phoneNumber = '';
  public photoURL = '';
  public ref = '';
  public roles = [Roles.User];
  public unread = [];
  public plan = '';
  public skills = [];
  public teacher = null;
  public hasAgreedToPrivacy = {
    version: '',
    status: false,
  };
  public socialLinks = [];
  public languages = [];
  public visibleFields = {
    visibleEmail: true,
    visibleCountry: true,
    visibleCity: true,
    visibleDateOfBirth: true,
    visibleGender: true,
    visiblePhoneNumber: true,
  };
  public dateBirth = null;
  public label = '';
  public gender = '';
  public updatedAt = new Date();

  constructor({
    id,
    uid = '',
    displayName = '',
    displaySurname = '',
    about = '',
    skills = [],
    email = '',
    learningPreferences = [],
    studentsPreferencesNotes = '',
    gender = '',
    unread = [],
    emailVerified = false,
    location = null,
    showCase = [],
    comments = [],
    notes = {},
    instruments = [],
    dateBirth,
    parents = null,
    socialLinks = [],
    languages = [],
    phoneNumber = '',
    visibleFields = {
      visibleEmail: true,
      visibleCountry: true,
      visibleCity: true,
      visibleDateOfBirth: true,
      visibleGender: true,
      visiblePhoneNumber: true,
    },
    photoURL = '',
    ref = '',
    roles = [Roles.User],
    plan = '',
    teacher = null,
    hasAgreedToPrivacy = {
      version: '',
      status: false,
    },
    updatedAt = new Date(),
  }) {
    this.id = id || uid || generateId();
    this.displayName = displayName ? displayName : this.email ? this.email.split('@')[0] : '';
    this.displaySurname = displaySurname;
    this.about = about;
    this.email = email;
    this.location = location;
    this.skills = skills;
    this.gender = gender;
    this.parents = parents;
    this.learningPreferences = learningPreferences;
    this.emailVerified = emailVerified;
    this.studentsPreferencesNotes = studentsPreferencesNotes;
    this.phoneNumber = phoneNumber;
    this.showCase = showCase;
    this.comments = comments;
    this.notes = notes;
    this.languages = languages;
    this.socialLinks = socialLinks;
    this.instruments = instruments;
    this.visibleFields = visibleFields;
    this.unread = unread;
    this.photoURL = photoURL;
    this.ref = ref;
    this.dateBirth = dateBirth?.toDate ? new Date(dateBirth.toDate()) : dateBirth || null;
    this.roles = roles;
    this.plan = plan;
    this.teacher = teacher;
    this.hasAgreedToPrivacy = hasAgreedToPrivacy;
    this.updatedAt = updatedAt;
  }

  getData(): IUserData {
    return {
      displayName: this.displayName,
      unread: this.unread,
      displaySurname: this.displaySurname,
      about: this.about,
      gender: this.gender,
      showCase: this.showCase,
      comments: this.comments,
      notes: this.notes,
      dateBirth: this.dateBirth,
      socialLinks: this.socialLinks,
      skills: this.skills,
      languages: this.languages,
      studentsPreferencesNotes: this.studentsPreferencesNotes,
      location: this.location,
      learningPreferences: this.learningPreferences,
      instruments: this.instruments,
      email: this.email,
      parents: this.parents,
      emailVerified: this.emailVerified,
      id: this.id,
      phoneNumber: this.phoneNumber,
      visibleFields: this.visibleFields,
      photoURL: this.photoURL,
      ref: this.ref,
      roles: this.roles,
      plan: this.plan,
      teacher: this.teacher,
      hasAgreedToPrivacy: this.hasAgreedToPrivacy,
    };
  }

  get fullName() {
    return `${this.displayName} ${this.displaySurname}`.trim();
  }

  getUserContactData(): IUserContactData {
    return {
      id: this.id,
      email: this.email,
      displayName: this.displayName,
      displaySurname: this.displaySurname,
      photoURL: this.photoURL,
      roles: this.roles,
    };
  }

  getUserParticipantData(): IUserContactData {
    return {
      id: this.id,
      email: this.email,
      displayName: this.displayName,
      displaySurname: this.displaySurname,
      photoURL: this.photoURL,
      roles: this.roles,
    };
  }

  getPlan() {
    return this.plan;
  }

  hasRole(role) {
    return this.roles.includes(role);
  }

  addRole(role) {
    if (!this.roles.includes(role)) {
      this.roles = [...this.roles, role];
    }
  }

  removeRole(role) {
    this.roles = this.roles.filter((r) => r !== role);
  }

  makeTeacher(params?) {
    this.addRole(Roles.Teacher);
    this.teacher = this.teacher || { visibleInSearch: true, schools: params?.schools || ['global'] };
    this.plan = 'FREE';
  }

  removeTeacher() {
    this.removeRole(Roles.Teacher);
  }

  removeStudent() {
    this.removeRole(Roles.Student);
  }

  isTeacher() {
    return this.hasRole(Roles.Teacher);
  }

  makeStudent() {
    this.addRole(Roles.Student);
  }

  isStudent() {
    return this.hasRole(Roles.Student);
  }
}
export { Roles };
