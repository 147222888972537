import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from './useAuth';
import routeNames from 'route-names';
import { removeFromLocalStorage } from 'services/localStorage';

export function useRequireNoAuth(redirectUrl = routeNames.chat) {
  const auth = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (auth.user.model) {
      const initialLocation = localStorage.getItem('initialLocation');
      if (initialLocation) {
        removeFromLocalStorage('initialLocation');
        history.push(initialLocation);
      } else {
        //Remeber TO DO
        history.push(redirectUrl);
      }
    }
  }, [auth, history, redirectUrl]);

  return auth;
}
