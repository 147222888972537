import { setDoc, collection, getDocs, where, query, getDoc, doc, deleteDoc } from 'firebase/firestore';
import { firestore } from './firebase';
import { v4 as uuidv4 } from 'uuid';
import { snapshotToArray } from './fb-helpers';
import { User } from 'models/User.Class';

export const createEvent = async (data: IEvent) => {
  const eventId = uuidv4();
  const eventRef = doc(firestore, 'events', eventId);

  const eventData = {
    ...data,
    id: eventId,
  };

  await setDoc(eventRef, eventData);
  return eventData;
};

export const getEventsByOwnerId = async (ownerId: string): Promise<IEvent[]> => {
  const eventRef = collection(firestore, 'events');
  const q = query(eventRef, where('ownerId', '==', ownerId));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    eventStartTimeDate: doc.data().eventStartTimeDate?.toDate(),
  })) as IEvent[];
};

export const getEventById = async (eventId: string): Promise<IEvent> => {
  const eventRef = doc(firestore, 'events', eventId);
  const docSnap = await getDoc(eventRef);
  if (docSnap.exists()) {
    return { ...docSnap.data(), eventStartTimeDate: docSnap.data()?.eventStartTimeDate?.toDate() } as IEvent;
  }
  return null;
};

export const getEventByEventInviteId = async (eventInviteId: string): Promise<IEvent | null> => {
  const eventsRef = collection(firestore, 'events');
  const q = query(eventsRef, where('eventInviteId', '==', eventInviteId));
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return null;
  }

  const doc = querySnapshot.docs[0];
  return { id: doc.id, ...doc.data(), eventStartTimeDate: doc.data().eventStartTimeDate?.toDate() } as IEvent;
};

export const getEventsByEventParticipantId = async (eventParticipantId: string): Promise<IEvent[] | null> => {
  const eventsRef = collection(firestore, 'events');
  const q = query(eventsRef, where('participantIds', 'array-contains', eventParticipantId));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    eventStartTimeDate: doc.data().eventStartTimeDate?.toDate(),
  })) as IEvent[];
};

export const addParticipantToEvent = async (eventId: string, participant: IEventParticipant) => {
  const eventRef = doc(firestore, 'events', eventId);
  const event = await getEventById(eventId);
  const participantId = participant?.id;

  if (event && participantId) {
    let newEvent;
    if (event?.participantIds?.length > 0) {
      if (!event.participantIds.includes(participantId)) {
        newEvent = {
          ...event,
          participantIds: [...event.participantIds, participantId],
        };
      }
    } else {
      newEvent = { ...event, participantIds: [participantId] };
    }

    if (event.participants?.length > 0) {
      if (!event.participants.find((p) => p.id === participantId)) {
        newEvent = { ...newEvent, participants: [...event.participants, participant] };
      }
    } else {
      newEvent = { ...newEvent, participants: [participant] };
    }

    if (newEvent) {
      await setDoc(eventRef, newEvent);
    }
  }
};

export const addEventInviteToEvent = async (eventId: string, eventInviteId: string) => {
  const eventRef = doc(firestore, 'events', eventId);
  const event = await getEventById(eventId);
  if (event) {
    const newEvent = { ...event, eventInviteId: eventInviteId };
    await setDoc(eventRef, newEvent);
  }
};

export const updateEvent = async (event: IEvent, merge: boolean = true) => {
  try {
    const eventRef = doc(firestore, 'events', event.id);
    await setDoc(eventRef, event, { merge });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const deleteEvent = async (eventId: string) => {
  try {
    const eventRef = doc(firestore, 'events', eventId);
    await deleteDoc(eventRef);
    return eventId;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export async function updateParticipantInEvents(participant: Partial<IEventParticipant>): Promise<IEvent[]> {
  const eventDocs = await getDocs(
    query(collection(firestore, 'events'), where('participantIds', 'array-contains', participant.id))
  );

  const eventsToUpdate = [];

  snapshotToArray(eventDocs).map((eventData: IEvent) => {
    let needsUpdate = false;

    eventData.participants = eventData.participants.map((p) => {
      if (p.id === participant.id) {
        // Check if the participant data has changed using isEqual
        p.fullName = participant.fullName;
        needsUpdate = true;
        return p;
      }
      return p;
    });

    if (needsUpdate) {
      eventsToUpdate.push(eventData);
    }

    return eventData;
  });

  const updatePromises = eventsToUpdate.map((event) => updateEvent(event));
  await Promise.all(updatePromises);

  return eventsToUpdate as IEvent[];
}

export function createEventParticipantFromUser(
  user: User,
  role: 'participant' | 'moderator' | 'judge' = 'participant',
  status: 'pending' | 'accepted' | 'rejected' = 'pending'
): IEventParticipant {
  const eventParticipant: IEventParticipant = {
    fullName: user.fullName,
    email: user.email,
    id: user.id,
    status: status,
    role: role,
  };
  return eventParticipant;
}

export interface IEvent {
  eventStartTimeDate: Date;
  eventName: string;
  eventDescription: string;
  eventLink: string;
  participantIds: string[];
  ownerId: string;
  id: string;
  message: string;
  eventInviteId?: string;
  participants?: IEventParticipant[];
  isCanceled?: boolean;
}

export interface IEventParticipant {
  fullName: string;
  id: string;
  email: string;
  status: 'pending' | 'accepted' | 'rejected';
  role: 'participant' | 'moderator' | 'judge';
}
