import { Button } from 'components/basic';
import React from 'react';
import { Link } from 'react-router-dom';

export const MenuButton = ({ children, ...props }) => {
  const styles = {
    ...props.style,
  };
  return (
    <Button variant="text" style={styles} component={Link} {...props}>
      <>{children}</>
    </Button>
  );
};
