import LoadingIndicator from 'components/LoadingIndicator';
import { useAuth } from 'hooks/useAuth';
import { useRequireAuth } from 'hooks/useRequireAuth';
import React, { lazy, Suspense } from 'react';
import { Box } from '@mui/material';

function intersection(array1: any[], array2: any[]) {
  if (!Array.isArray(array1) || !Array.isArray(array2)) return [];
  return array1.filter((value) => array2.includes(value));
}

const loadable = (importFunc: any, { fallback = null }, roles?: string[]) => {
  const LazyComponent = lazy(importFunc);

  return (props: any) => {
    const auth = roles ? useRequireAuth() : useAuth();
    const user = auth.user;

    if (roles?.length && (user?.model?.roles || user?.model === false)) {
      if (!intersection(user?.model?.roles, roles).length) {
        return <Box sx={{ textAlign: 'center', py: 4, px: 2 }}>You don't have permissions to see this content</Box>;
      }
    }

    if (user.model === null) {
      return <LoadingIndicator />;
    } else {
      return (
        <Suspense fallback={fallback}>
          <LazyComponent {...props} />
        </Suspense>
      );
    }
  };
};

export default loadable;
