import React from 'react';
import Select from '@mui/material/Select';
import { themeVariables } from 'theme';

const SelectBasic = ({ children = undefined, placeholder = '', sx = {}, ...props }) => {
  return (
    <div style={{ position: 'relative' }}>
      {!!placeholder && (
        <div
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            color: 'rgba(0, 0, 0, 0.6)',

            fontWeight: 400,
            transform: 'translate(14px, -9px) scale(0.75)',
            transformOrigin: 'top left',
            padding: '0 3px',
            fontSize: '1rem',
            lineHeight: '1.4375em',
            letterSpacing: '0.00938em',
            background: '#fff',
            zIndex: 1,
            whiteSpace: 'nowrap',
            maxWidth: 'calc(133% - 40px)',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          {placeholder}
        </div>
      )}
      <Select size="small" sx={{ borderRadius: themeVariables.btnBorderRadius, ...sx }} {...props}>
        {children}
      </Select>
    </div>
  );
};

export default SelectBasic;
