import { Box, Avatar, Typography, Link } from '@mui/material';
import React from 'react';
import { SubDescription } from './Typography';
import { ReactComponent as LinkUrl } from 'assets/profileIcons/u_link-alt.svg';
export function ProfileItemInfo({
  additionalLabel,
  label,
  subLabel,
  downLabel,
  link,
}: {
  additionalLabel?: string;
  label?: string;
  subLabel?: string;
  downLabel?: any;
  link?: string;
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: { xs: 'column', lg: 'column' },
        gap: 2,
        alignItems: { xs: 'flex-start' },
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '12px',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}>
              <Typography
                sx={{
                  color: '#101828',
                  fontWeight: '600',
                }}>
                {label}
              </Typography>
              <SubDescription>{subLabel}</SubDescription>
            </Box>
            {additionalLabel && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                  cursor: 'pointer',
                  color: '#452D9E',
                }}></Box>
            )}
          </Box>

          {downLabel && (
            <Typography sx={{ color: '#667085', fontSize: '14px', fontWeight: 500, textTransform: 'capitalize' }}>
              {typeof downLabel.toDate === 'function' ? downLabel.toDate().toLocaleDateString() : downLabel}
            </Typography>
          )}
        </Box>
      </Box>
      {additionalLabel && link && (
        <Box>
          <Link
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px',
              color: '#452D9E',
              fontSize: '16px',
              fontWeight: 600,
            }}>
            {additionalLabel}
            <LinkUrl />
          </Link>
        </Box>
      )}
    </Box>
  );
}
