import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Divider, Paper, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { ValidationErrorsContainer } from 'containers/ValidationErrors/ValidationErrorsContainer';
import { addOrUpdateParticipantInEvent, getEventInviteById } from 'firebase-db/EventInviteService';
import { useUser } from 'hooks/useUser';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { IEventParticipant } from 'firebase-db/EventService';

const RegisterEventInvitePage = () => {
  const user = useUser().model;
  const eventId: { eventInviteId: string } = useParams();
  const [isRedirect, setRedirect] = useState(false);
  const history = useHistory();

  const schema = Yup.object({
    firstName: Yup.string()
      .min(1, 'First name must be at least 1 characters')
      .required('First name is required for indifcation'),
    lastName: Yup.string()
      .min(1, 'Last name must be at least 1 characters')
      .required('Last name is required for indifcation'),
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: '',
      lastName: '',
    },
  });

  const onSubmit = async (data) => {
    reset({
      firstName: '',
      lastName: '',
    });
    const eventInviteId = await getEventInviteById(eventId.eventInviteId).then((event) => event.eventId);
    const userId = user.id;
    const fullName = data.firstName.trim() + ' ' + data.lastName.trim();
    if (fullName.length < 2) {
      toast.error('Full name must be at least 2 characters');
      return;
    }
    const infoUser: IEventParticipant = {
      fullName: fullName,
      id: userId,
      email: user.email,
      status: 'accepted',
      role: 'participant',
    };

    addOrUpdateParticipantInEvent(eventInviteId, infoUser);
    setRedirect(true);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100vh',
        gap: '40px',
      }}>
      <Paper
        className="paper-register"
        sx={{ padding: '20px', maxWidth: '350px', borderRadius: '8px', textAlign: 'center', color: 'blueviolet' }}>
        Here you can register to the event, and choose a name under which you will join the event
      </Paper>
      <Button disabled={!isRedirect} variant="contained" onClick={() => history.goBack()}>
        {'< Back'}
      </Button>
      <form
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        onSubmit={handleSubmit(onSubmit)}>
        <Paper sx={{ padding: '20px', borderRadius: '8px 8px 0 0', textTransform: 'uppercase' }} elevation={5}>
          Registration for event
        </Paper>
        <Paper
          sx={{
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            gap: '24px',
            width: '400px',
            padding: '35px',
            '@media (max-width: 500px)': { width: '80%' },
          }}
          elevation={5}>
          <ValidationErrorsContainer>
            <TextField disabled={isRedirect} {...register('firstName')} variant="outlined" label="First Name" />
            <Typography
              sx={{
                transition: 'all 0.3s ease-in-out',
                opacity: errors.firstName ? 1 : 0,
                visibility: errors.firstName ? 'visible' : 'hidden',
                color: errors.firstName ? 'red' : 'black',
              }}>
              First name must be at least 1 characters
            </Typography>
          </ValidationErrorsContainer>

          <ValidationErrorsContainer>
            <TextField disabled={isRedirect} {...register('lastName')} variant="outlined" label="Last Name" />
            <Typography
              sx={{
                transition: 'all 0.3s ease-in-out',
                opacity: errors.lastName ? 1 : 0,
                visibility: errors.lastName ? 'visible' : 'hidden',
                color: errors.lastName ? 'red' : 'black',
              }}>
              Last name must be at least 1 characters
            </Typography>
          </ValidationErrorsContainer>
          <Divider />
          <Button disabled={isRedirect} variant="contained" type="submit">
            Registration
          </Button>
          <Alert sx={{ textAlign: 'center' }} severity="info">
            Remember to use a name so that the teacher can recognize you.
          </Alert>
        </Paper>
      </form>
    </Box>
  );
};

export default RegisterEventInvitePage;
