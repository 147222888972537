import React from 'react';
import Grid, { GridProps } from '@mui/material/Grid';

interface GridBasicProps {
  row?: boolean;
  center?: boolean;
  justify?: string;
}
const GridBasic = ({ row, center, justify, sx, children = undefined, ...props }: GridProps & GridBasicProps) => {
  const styles: any = {
    flexDirection: row ? 'row' : 'column',
    alignItems: center ? 'center' : 'flex-start',
    justifyContent: justify ? justify : center ? 'center' : '',
    ...sx,
  };
  return (
    <Grid sx={sx} {...props}>
      <>{children}</>
    </Grid>
  );
};

export default GridBasic;
