import { fAnalytics } from 'firebase-db';
import { logEvent } from 'firebase/analytics';

export const logCurrentPage = () => {
  logEvent(fAnalytics, 'currentScreen', { screen: window.location.pathname });
};

export const handleLogEvent = (name, props?) => {
  logEvent(fAnalytics, name, props);
};
