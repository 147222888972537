import { useEffect, useRef, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { firestore } from 'firebase-db';
import { getMessageById } from 'firebase-db/ChatService';
import { toast } from 'react-toastify';
import { useAuth } from './useAuth';

const useUnreadMessages = () => {
  const {
    user: { model: user },
    ...auth
  } = useAuth();
  const currentLength = useRef(0);
  const [length, setLength] = useState(0);
  const userId = user?.id;
  useEffect(() => {
    if (!userId) return;
    if (currentLength.current === 0) {
      currentLength.current = user.unread.length;
      setLength(user.unread.length);
    }
    const getMessage = async (roomId, messageId) => {
      const message = await getMessageById(roomId, messageId);
      return message;
    };

    const userDocRef = doc(firestore, 'users', userId);

    const unsub = onSnapshot(userDocRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();

        if (currentLength.current === 0 && data?.unread?.length !== 0) {
          currentLength.current = data?.unread?.length;
        } else if (currentLength.current && data?.unread?.length > currentLength.current) {
          const lastMessage = data?.unread?.[data?.unread?.length - 1];
          getMessage(lastMessage.room, lastMessage.message).then((resp) => {
            toast.info(`You have new message: ${resp.text}`);
          });
        }
        currentLength.current = data?.unread?.length;
        setLength(user.unread.length);
      } else {
        console.log('No such document!');
      }
    });

    return () => unsub();
  }, [userId]);

  return currentLength.current;
};

export default useUnreadMessages;
