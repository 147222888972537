import React from 'react';
import { Box } from '@mui/material';

export function ProfilePageComponentWrapper({ children, inView }: { children: React.ReactNode; inView?: any }) {
  return (
    <Box
      ref={inView}
      sx={{
        border: '1px solid #EAECF5',
        borderRadius: '12px',
        flex: 0,
        padding: { xs: '16px', md: '32px' },
        bgcolor: { md: '#FCFCFD' },
        fontFamily: 'Manrope',
        // border: ' 1px solid #EAECF5',
        // borderRadius: '12px',
        // padding: { xs: '16px', md: '32px' },
        // bgcolor: '#FCFCFD',
        // fontFamily: 'Manrope',
      }}>
      {children}
    </Box>
  );
}
