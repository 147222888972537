import {
  collection,
  doc,
  query,
  where,
  getDocs,
  addDoc,
  deleteDoc,
  runTransaction,
  getDoc,
  setDoc,
} from 'firebase/firestore';
import { IRoomData, Room, RoomStatus, RoomTypes } from 'models/Room.Class';
import { saveUserAction } from './ActionsService';
import { IUserParticipantData } from 'models/User';
import { snapshotToArray } from './fb-helpers';
import { firestore } from './firebase';
import { v4 as uuidv4 } from 'uuid';
import { addChatMessage } from './ChatService';
import { isEqual } from 'lodash';

const roomCollectionName = 'rooms';

export function getNewRoomData({
  id,
  name = '',
  size = 2,
  type = RoomTypes.private,
  status = RoomStatus.active,
  participantIds,
  participants,
}: Partial<IRoomData>) {
  size = participantIds?.length > size ? participantIds.length : size;
  const date = new Date().toISOString();
  const customId: string = id ? id : uuidv4();
  return {
    id: customId,
    status,
    type,
    size,
    name,
    participantIds: participantIds?.sort(),
    participants,
    createdAt: date,
    updatedAt: date,
  };
}

export async function createRoom(data) {
  try {
    const roomData = getNewRoomData(data);
    if (roomData?.participants && roomData?.participantIds) {
      const roomDocs = await getDocs(
        query(collection(firestore, roomCollectionName), where('participantIds', '==', data.participantIds.sort()))
      );

      const date = new Date().toISOString();

      if (!roomDocs.empty) {
        const updatePromises = [];
        roomDocs.forEach((roomDoc) => {
          const room = roomDoc.data();
          const uuid = room?.participantIds.sort().join('-');
          if (!uuid) {
            updatePromises.push(removeRoom(roomDoc.id));
          } else {
            updatePromises.push(
              setDoc(doc(firestore, roomCollectionName, roomDoc.id), { updatedAt: date }, { merge: true })
            );
          }
        });
        await Promise.all(updatePromises);
        return Object.keys(updatePromises)[0];
      } else {
        const roomDocRef = await setDoc(doc(firestore, roomCollectionName, roomData.id), roomData);
        return roomDocRef;
      }
    } else if (roomData?.id) {
      const roomDocRef = await setDoc(doc(firestore, roomCollectionName, roomData.id), roomData);
      return roomDocRef;
    }
  } catch (e) {
    console.error(e);
  }
}

export async function createRoomWithBot(data, botData, initialMessage: string) {
  const roomData = getNewRoomData(data);
  try {
    await createRoom(roomData);
    const roomDocRef = await getDoc(doc(firestore, roomCollectionName, roomData.id));
    const message = await addChatMessage({ roomId: roomData.id, text: initialMessage, fromId: botData.id });
    await updateRoom({ id: roomData.id, lastMessage: message });
    return roomDocRef;
  } catch (e) {
    console.error(e);
  }
}

export async function activateRoom(room) {
  return setDoc(doc(firestore, roomCollectionName, room.id), { status: RoomStatus.active }, { merge: true });
}

export async function joinRoom(roomId, info) {
  saveUserAction({ name: 'join_room', roomId: roomId, ...info });
  const date = new Date().toISOString();

  await setDoc(doc(firestore, roomCollectionName, roomId), { updatedAt: date }, { merge: true });
  if (info.email) {
    await addDoc(collection(firestore, `${roomCollectionName}/${roomId}/actions`), { ...info, joined: date });
  }
}

export function leaveRoom(roomId, info) {
  saveUserAction({ name: 'leave_room', roomId: roomId, ...info });
  if (info.email) {
    return addDoc(collection(firestore, `${roomCollectionName}/${roomId}/actions`), {
      ...info,
      left: new Date().toISOString(),
    });
  }
}

export async function getRoom(roomId) {
  try {
    const roomDoc = await getDoc(doc(firestore, roomCollectionName, roomId));
    if (roomDoc.exists()) {
      const roomData = roomDoc.data();
      roomData.id = roomId;
      return new Room(roomData as IRoomData);
    }
  } catch (err) {
    console.error(err);
  }
}

export function updateRoom(room: Partial<IRoomData>, noDateUpdate = false) {
  if (!noDateUpdate) {
    room.updatedAt = new Date().toISOString();
  }
  room.participantIds = room?.participantIds?.sort();
  console.log('Success', room);
  return setDoc(doc(firestore, roomCollectionName, room.id), room, { merge: true });
}

export function removeRoom(roomId) {
  return deleteDoc(doc(firestore, roomCollectionName, roomId));
}

export async function deleteRoomAndConnections(room) {
  try {
    const roomRef = doc(firestore, roomCollectionName, room.id);
    const roomDoc = await getDoc(roomRef);

    if (!roomDoc.exists()) {
      console.error('Room does not exist.');
      return;
    }

    const roomData = roomDoc.data();

    if (roomData.isPrivate) {
      // Delete users from each other's contacts for both
      const userId1 = roomData.participantIds[0];
      const userId2 = roomData.participantIds[1];
      const connection1 = doc(firestore, `user/${userId1}/connections`, userId2);
      const connection2 = doc(firestore, `user/${userId2}/connections`, userId1);

      await runTransaction(firestore, async (transaction) => {
        // Delete connections and the room document within the transaction
        transaction.delete(connection1);
        transaction.delete(connection2);
        transaction.delete(roomRef);
      });

      console.log(`Room ${room.id} and connections deleted successfully.`);
    } else {
      await deleteDoc(roomRef);
      console.log(`Room ${room.id} deleted successfully.`);
    }
  } catch (e) {
    console.error('Error deleting room and connections:', e);
  }
}

export function deactivateRoom(roomId) {
  return setDoc(doc(firestore, roomCollectionName, roomId), { status: RoomStatus.inactive }, { merge: true });
}

export async function getRoomById(roomId) {
  const roomDoc = await getDoc(doc(firestore, roomCollectionName, roomId));
  if (roomDoc.exists()) {
    return new Room(roomDoc.data() as IRoomData);
  }
  return null;
}

export async function getRoomsByParticipantId(participantId): Promise<Room[]> {
  try {
    if (!participantId) {
      throw new Error('Participant id was not provided:' + participantId);
    }
    const roomDocs = await getDocs(
      query(collection(firestore, roomCollectionName), where('participantIds', 'array-contains', participantId))
    );

    const rooms = snapshotToArray(roomDocs).map((r) => new Room(r));
    return rooms as Room[];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function updateParticipantInRooms(participant: IUserParticipantData): Promise<Room[]> {
  const roomDocs = await getDocs(
    query(collection(firestore, roomCollectionName), where('participantIds', 'array-contains', participant.id))
  );

  const roomsToUpdate = [];

  snapshotToArray(roomDocs).map((roomData) => {
    let needsUpdate = false;

    roomData.participants = roomData.participants.map((p) => {
      if (p.id === participant.id) {
        // Check if the participant data has changed using isEqual
        if (!isEqual(p, participant)) {
          needsUpdate = true;
          return participant;
        }
      }
      return p;
    });

    const room = new Room(roomData as any);

    if (needsUpdate) {
      roomsToUpdate.push(room);
    }

    return room;
  });

  const updatePromises = roomsToUpdate.map((room: Room) => updateRoom(room.getData()));
  await Promise.all(updatePromises);

  return roomsToUpdate as Room[];
}

export async function getRoomLessonDurationById(roomId) {
  const roomDoc = await getDoc(doc(firestore, roomCollectionName, roomId));
  if (roomDoc.exists() && roomDoc.data().status === RoomStatus.active) {
    return roomDoc.data().lessonDuration;
  }
  return null;
}

export async function updateRoomDuration(roomId, lessonDuration) {
  try {
    await setDoc(doc(firestore, roomCollectionName, roomId), { lessonDuration: lessonDuration }, { merge: true });
  } catch (e) {
    console.error(e);
  }
}
