import * as serviceWorker from './serviceWorker';
import './index.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ThemeProvider } from '@mui/material/styles';
import App from 'containers/App';
import { ProvideAuth } from 'hooks/useAuth';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { theme } from 'theme';
import { ProvideUser } from 'hooks/useUser';
import { store } from 'redux/store';

import CookieModal from 'components/CookieModal/CookieModal';
import { runGlobalScripts } from 'GlobalScripts';

import { createRoot } from 'react-dom/client';

const isProduction = process.env.NODE_ENV === 'production';

if (isProduction) {
  runGlobalScripts();
}

const composeWrappers = (wrappers: React.FunctionComponent[]): React.FunctionComponent => {
  return wrappers.reduce((Acc, Current): React.FunctionComponent => {
    return (props) => (
      <Current>
        <Acc {...props} />
      </Current>
    );
  });
};

const SuperProvider = composeWrappers([
  // React.StrictMode included by default in createRoot
  (props) => <Provider store={store} children={props.children} />,
  (props) => <BrowserRouter children={props.children} />,
  (props) => <ProvideAuth>{props.children}</ProvideAuth>,
  (props) => <ProvideUser>{props.children}</ProvideUser>,
  (props) => <ThemeProvider theme={theme}>{props.children}</ThemeProvider>,
]);

// Use createRoot API for React 18
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <SuperProvider>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <App />
      <CookieModal />
    </LocalizationProvider>
  </SuperProvider>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. This comes with some pitfalls.
// Learn more about service workers and using the new registration: https://cra.link/PWA
serviceWorker.unregister();
