const initState = { isMenuVisible: true, isCallStarted: false, isFullscreen: false };

export const uiReducer = (state = initState, action) => {
  switch (action.type) {
    case 'STARTED_CALL':
      return { ...state, isCallStarted: true };
    case 'FINISHED_CALL':
      return { ...state, isCallStarted: false };
    case 'HIDE_MENU':
      return { ...state, isMenuVisible: false };
    case 'SHOW_MENU':
      return { ...state, isMenuVisible: true };
    case 'ENABLE_FULLSCREEN':
      return { ...state, isFullscreen: true };
    case 'DISABLE_FULLSCREEN':
      return { ...state, isFullscreen: false };
    default:
      return state;
  }
};
