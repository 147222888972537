import { colorConstants } from './../components/Messenger/Chat/colorConstants';
import { UserContact } from './Contact.Class';
import { IMessage, IUserParticipantData } from './User';

export enum RoomTypes {
  private = 'private',
  group = 'group',
}

export enum RoomStatus {
  active = 'active',
  inactive = 'inactive',
}
export interface ITags {
  id: string;
  name: string;
  description: string;
  color: string;
  url: string;
  guests: string[];
}
export const HomeWorkTag = {
  id: 'HomeworkTagId',
  name: 'Homework',
  description: 'Homework for your students',
  color: colorConstants[2].primary,
  url: null,
  guests: null,
};

export interface IRoomData {
  id: string;
  name: string;
  type: RoomTypes;
  size: number;
  status?: RoomStatus;
  imageURL?: string;
  updatedAt?: string;
  createdAt?: string;
  participantIds: string[];
  participants: IUserParticipantData[];
  messages?: IMessage[];
  lastMessage?: IMessage;
  tags?: ITags[] | [];
  unread?: Record<string, string[]>;
  pinnedMessages?: IMessage[];
}

export class Room {
  public id: string;
  public name = '';
  public type: RoomTypes = RoomTypes.private;
  public size: number;
  public status = RoomStatus.active;
  public imageURL: string;
  public updatedAt = '';
  public createdAt = '';
  public pinnedMessages: IMessage[];
  public tags: ITags[] = [];
  public participantIds: string[] = [];
  public participants: IUserParticipantData[] = [];
  public unread: Record<string, string[]> = {};
  public messages: IMessage[] = [];
  public lastMessage: IMessage = null;

  constructor({
    id,
    name = '',
    type = RoomTypes.private,
    size = 2,
    status = RoomStatus.active,
    imageURL = '',
    updatedAt,
    createdAt,
    pinnedMessages = [],
    tags = [],
    participantIds = [],
    participants = [],
    unread = {},
    messages = [],
    lastMessage = null,
  }: IRoomData) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.size = size;
    this.status = status;
    this.imageURL = imageURL;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
    this.pinnedMessages = pinnedMessages;
    this.tags = tags;
    this.participantIds = participantIds;
    this.participants = participants;
    this.unread = unread;
    this.messages = messages;
    this.lastMessage = lastMessage;
  }

  get isPrivate() {
    return this.type === RoomTypes.private;
  }

  displayRoomName(currentUserId = '') {
    if (this.name) {
      return this.name;
    }
    if (!this.isPrivate && !!this.name) {
      return this.name;
    } else {
      const names = this.getRoomParticipantsWithoutId(currentUserId)
        .map((p) => new UserContact(p).fullName)
        .join(', ');
      return (!this.isPrivate ? 'Group: ' : '') + names;
    }
  }

  getRoomImage(currentUserId) {
    if (currentUserId && this.type === RoomTypes.private) {
      return this.getRoomParticipantsWithoutId(currentUserId)?.[0]?.photoURL || this.imageURL;
    }
    return this.imageURL;
  }

  addParticipant(participant: IUserParticipantData) {
    if (participant.id && !this.participantIds.includes(participant.id)) {
      this.participantIds.push(participant.id);
    }
    if (participant.id && !this.participants.find((p) => p.id === participant.id)) {
      this.participants.push(participant);
    }
  }

  getParticipantsArray(): IUserParticipantData[] {
    return this.participants;
  }

  getRoomParticipantsWithoutId(id: string): IUserParticipantData[] {
    return this.participants.filter((p) => p.id !== id);
  }

  getData(): IRoomData {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      size: this.size,
      status: this.status,
      imageURL: this.imageURL,
      updatedAt: this.updatedAt,
      createdAt: this.createdAt,
      pinnedMessages: this.pinnedMessages,
      tags: this.tags,
      participantIds: this.participantIds,
      participants: this.participants,
      unread: this.unread,
      messages: this.messages,
      lastMessage: this.lastMessage,
    };
  }
}
