// @ts-nocheck
/* eslint-disable */
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export function runGlobalScripts() {
    Sentry.init({
        dsn: 'https://98a1a55af8814c2eb33c1af3a616435f@o511594.ingest.sentry.io/5609099',
        integrations: [new Integrations.BrowserTracing()],
        environment: process.env.NODE_ENV,
        tracesSampleRate: 1.0,
    });

    (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
            'gtm.start':
                new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-TJ4LNRZ');


    // Facebook Pixel Code
    const w = (window as any);
    !function (f, b, e, v, n, t, s) {
        if (f.fbq) return; n = f.fbq = function () {
            n.callMethod ?
                n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        };
        if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
        n.queue = []; t = b.createElement(e); t.async = !0;
        t.src = v; s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s)
    }(w, document, 'script',
        'https://connect.facebook.net/en_US/fbevents.js');
    w.fbq('init', '1318636438682519');
    w.fbq('track', 'PageView');
}
