import { Box, Divider, SxProps, TextFieldProps, Typography } from '@mui/material';
import { InputAdornment, TextField } from 'components/basic';

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Control, Controller, FieldValues, RegisterOptions } from 'react-hook-form';
import { InputLabel } from '@mui/material';

export function ProfileInput<T>({
  control,
  rules,
  required,
  name,
  errors,
  startIcon,
  containerSx,
  label,
  formatValue,
  endIcon,
  handleChange,
  ...props
}: {
  control?: Control<T>;
  label?: string;
  required?: boolean;
  rules?: RegisterOptions;
  errors?: boolean;
  name?: keyof T | string;
  formatValue?: (val: any) => any;
  handleChange?: (val) => any;
  startIcon?: React.ReactNode;
  containerSx?: SxProps;
  endIcon?: React.ReactNode;
} & TextFieldProps) {
  const handleCustomChange = (event: any, onChangeHandler: (...event: any[]) => void, value: any) => {
    onChangeHandler(event);
    if (handleChange) {
      handleChange(event);
    }
  };
  return (
    <Controller
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <StyledProfileTextField
            {...props}
            error={error || (errors === true ? { message: 'error in this field' } : errors)}
            label={label}
            required={required}
            containerSx={containerSx}
            startIcon={startIcon}
            endIcon={endIcon}
            value={value}
            onChange={(event) => handleCustomChange(event, onChange, value)}
          />
        );
      }}
      rules={rules}
      control={control as Control<FieldValues>}
      name={name as string}
    />
  );
}

export function StyledProfileTextField({
  label,
  color,
  required,
  startIcon,
  error,
  containerSx,
  endIcon,
  ...props
}: {
  label?: string;
  color?: string;
  required?: boolean;
  error?: any;
  startIcon?: React.ReactNode;
  containerSx?: SxProps;
  endIcon?: React.ReactNode;
} & TextFieldProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', ...containerSx }}>
      {label && <CustomProfileLabel label={label} required={required} />}

      <TextField
        {...props}
        error={!!error}
        fullWidth
        color={error ? 'error' : color || 'primary'}
        //helperText={error?.message}
        InputProps={{
          startAdornment: startIcon && (
            <InputAdornment position="start">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '14px',
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  {startIcon}
                </Box>
                <Divider sx={{ height: '20px', borderColor: error ? '#DE5858' : '#EAECF5' }} orientation="vertical" />
              </Box>
            </InputAdornment>
          ),
          endAdornment: endIcon && (
            <InputAdornment position="start">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '14px',
                }}>
                <Divider sx={{ height: '20px', borderColor: error ? '#DE5858' : '#EAECF5' }} orientation="vertical" />
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                  {endIcon}
                </Box>
              </Box>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}

export function CustomProfileLabel({ label, required }: { label?: string; required?: boolean }) {
  const [maxLabelHeight, setMaxLabelHeight] = useState<number | null>(null);

  useEffect(() => {
    const updateMaxHeight = () => {
      const labels = document.querySelectorAll('.custom-label');
      let maxHeight = 0;
      labels.forEach((label) => {
        const element = label as HTMLElement;
        element.style.height = 'auto';
        const height = element.getBoundingClientRect().height;
        maxHeight = Math.max(maxHeight, height);
      });
      labels.forEach((label) => {
        const element = label as HTMLElement;
        element.style.height = `${maxHeight}px`;
      });
      setMaxLabelHeight(maxHeight);
    };
    updateMaxHeight();
    window.addEventListener('resize', updateMaxHeight);
    return () => {
      window.removeEventListener('resize', updateMaxHeight);
    };
  }, []);

  return (
    <InputLabel
      className="custom-label"
      sx={{
        fontSize: '16px',
        color: '#101828',
        fontWeight: '600',
        display: 'flex',
        flexDirection: 'row',
        gap: '5px',
        whiteSpace: 'normal',
        height: maxLabelHeight ? `${maxLabelHeight}px` : 'auto',
      }}>
      {label}
      {required && <Typography sx={{ color: '#452D9E', fontSize: '16px', fontWeight: '700' }}>*</Typography>}
    </InputLabel>
  );
}
