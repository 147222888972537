import LoadingIndicator from 'components/LoadingIndicator';
import loadable from 'helpers/loadable';
import React from 'react';

/**
 * Asynchronously loads the component for HomePage
 */
export default loadable(() => import('./ContactPage'), {
  fallback: <LoadingIndicator />,
});
