import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import styled from 'styled-components';
import { themeVariables } from 'theme';

class TextFieldBasic extends React.Component<TextFieldProps & { rounded?: boolean }> {
  constructor(props) {
    super(props);
  }

  render() {
    return <TextField size="small" {...this.props} />;
  }
}

const StyledTextField = styled(TextFieldBasic)`
  .MuiInputBase-root {
    border-radius: ${themeVariables.btnBorderRadius};
  }
`;

export default StyledTextField;
