import styled from 'styled-components';
import { themeOptions } from 'theme';
import { device } from 'components/variables';

const H1 = styled.h1`
  text-align: ${(props) => props.align};
  color: ${themeOptions.palette.secondary?.main || '#303A7F'};
  font-size: 32px;
  margin: 1em 0;
  text-transform: uppercase;

  @media ${device.mobileS} {
    font-size: 24px;
  }
`;

export default H1;
