import LoadingIndicator from 'components/LoadingIndicator';
import loadable from 'helpers/loadable';
import React from 'react';
import { Roles } from 'models/User.Class';

export default loadable(
  () => import('./AdminSignals'),
  {
    fallback: <LoadingIndicator />,
  },
  [Roles.Admin]
);
