const initState = {  };

export const activeSignalReducer = (state = initState, action) => {
  switch (action.type) {
    case 'ADD_SIGNAL':
      return { ...state, [action.payload.data.name]: action.payload };
    default:
      return state;
  }
};
