import { useCallback, useEffect, useState } from 'react';

export function useWindowSize() {
  // eslint-disable-next-line no-restricted-globals
  const client = self;
  const isClient = client && !!client.innerWidth;

  const getSize = useCallback(() => {
    return {
      width: isClient ? client.innerWidth : undefined,
      height: isClient ? client.innerHeight : undefined,
      portrait: isClient && client.innerWidth < client.innerHeight,
      landscape: isClient && client.innerWidth >= client.innerHeight,
    };
  }, [client.innerHeight, client.innerWidth, isClient]);

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect((): (() => any) => {
    if (!isClient) {
      return;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    client.addEventListener('resize', handleResize);
    return () => client.removeEventListener('resize', handleResize);
  }, [client, getSize, isClient]); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}
