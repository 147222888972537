import React, { useEffect, useState } from 'react';
import { ModalHeader } from './ModalHeader';
import { Box, Button, Dialog, FormControlLabel, Radio, RadioGroup, styled, Typography } from '@mui/material';
import { Title } from './Typography';
import { useFieldArray, useForm } from 'react-hook-form';
import { ProfileInput } from 'components/ProfileInput/ProfileInput';
import { ReactComponent as NoteIcon } from 'assets/profileIcons/u_clipboard-notes.svg';
import { useAuth } from 'hooks/useAuth';
import { User } from 'models/User.Class';
import { saveUser } from 'firebase-db/UserService';

export function NotesModal({ open, setOpen }) {
  const auth = useAuth();
  const user: User = auth.user.model;

  const { handleSubmit, control, register, reset } = useForm({
    defaultValues: {
      notes: [
        {
          noteTitle: '',
          noteDescription: '',
        },
      ],
    },
  });

  const { fields: notesFields, append: notesAppend, remove: notesRemove } = useFieldArray({ name: 'notes', control });

  function onSubmit(data) {
    const newData = data?.notes?.map((note) => ({ title: note.noteTitle, titleDescription: note.noteDescription }));
    const newValues = {
      ...user.getData(),
      teacher: { ...user.getData().teacher, notes: [...user.getData().teacher.notes, ...newData] },
    };

    saveUser(newValues, { merge: false }).then(() => {});
    reset();
    handleClose();
  }

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <ModalHeader handleClose={handleClose} title="Notes"></ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ padding: '0px 24px 16px 24px', backgroundColor: '#FCFCFD' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: '8px' }}>
            <NotesModalSection
              title="Private Notes"
              description="This section is for teachers to jot down key details about the student.  You can include contact info, your rate, plans, program list, events,  comments, and feedback about this student. These notes are for your eyes  only."
              customIcon={<NoteIcon />}
            />
            {notesFields.map((item, index) => {
              return (
                <Box key={index} sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                  <ProfileInput
                    placeholder="Enter your title"
                    label="Title"
                    control={control}
                    name={`notes.${index}.noteTitle`}
                  />
                  <ProfileInput
                    placeholder="Enter your description"
                    label="Description"
                    control={control}
                    name={`notes.${index}.noteDescription`}
                    multiline
                    rows={4}
                  />
                </Box>
              );
            })}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '24px' }}>
            {notesFields.length > 1 ? (
              <Button
                onClick={() => notesRemove(notesFields.length - 1)}
                sx={{
                  fontSize: '16',
                  fontWeight: '600',
                  color: '#DE5858',
                  textDecoration: 'underline',
                  textTransform: 'lowercase',
                  padding: 0,
                }}>
                delete
              </Button>
            ) : (
              <Box></Box>
            )}
            <Button
              onClick={() =>
                notesAppend({
                  noteTitle: '',
                  noteDescription: '',
                })
              }
              sx={{
                fontSize: '16',
                fontWeight: '600',
                color: '#452D9E',
                textDecoration: 'underline',
                padding: 0,
                textTransform: 'lowercase',
              }}>
              Add a new note+
            </Button>
          </Box>
          <Button
            type="submit"
            variant="contained"
            sx={{
              fontSize: '18px',
              fontWeight: 600,
              textTransform: 'capitalize',
              backgroundColor: '#452D9E',
              width: '100%',
            }}>
            Save
          </Button>
        </Box>
      </form>
    </Dialog>
  );
}

export function NotesModalSection({ title, description, customIcon }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        {customIcon}
        <Title>{title}</Title>
      </Box>
      <Typography sx={{ color: '#667085', fontSize: '14px', fontWeight: 500 }}>{description}</Typography>
    </Box>
  );
}
