// Import the required modules from Firebase 9

import {
  collection,
  doc,
  addDoc,
  orderBy,
  query,
  updateDoc,
  deleteDoc,
  startAt,
  getDocs,
  where,
  writeBatch,
  getDoc,
} from 'firebase/firestore';
import { firestore } from './firebase';
import { IMessage, IMessageData } from 'models/User';

// Function to send a chat message
export async function addChatMessage({ roomId, id, ...message }: IMessageData): Promise<IMessage> {
  try {
    // Get a reference to the messages collection in the specified room
    const messagesRef = collection(firestore, 'rooms', roomId, 'messages');

    // Add a new document with the message data and let Firestore generate the ID
    const messageData: IMessageData = {
      roomId,
      tags: [],
      ...message,
      createdAt: new Date(),
    };

    const docRef = await addDoc(messagesRef, messageData);

    // Return the new message object with the generated ID
    return { id: docRef.id, ...messageData };
  } catch (error) {
    console.error('Error adding document: ', error);
    throw error; // rethrow the error after logging it
  }
}


export async function getDataFromFirestore(roomId: string, queryMessage: string): Promise<IMessage[] | null> {
  const messagesCollection = collection(firestore, `rooms/${roomId}/messages`);

  try {
    const q = query(messagesCollection, orderBy('text'), startAt(queryMessage));
    const snapshot = await getDocs(q);

    const messages: IMessage[] = [];

    snapshot.docs.forEach((doc) => {
      messages.push(doc.data() as IMessage);
    });
    return messages;
  } catch (error) {
    if (error.name === 'AbortError') {
      console.log('Operation aborted.');
    } else {
      console.error('Error fetching data:', error.message);
    }
    return null;
  }
}
export async function getMessagesByTag(roomId: string, idTag: string) {
  const messagesRef = collection(firestore, 'rooms', roomId, 'messages');

  // Query to filter messages based on the idTag
  const q = query(messagesRef, where('tags', 'array-contains', idTag));

  try {
    // Fetching messages that match the query
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      return []; // Return empty array if no messages found
    }

    // Extracting messages from the query snapshot
    const messages = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return messages;
  } catch (error) {
    console.error('Error fetching messages:', error);
    return [];
  }
}

export async function getMessagesWithImg(roomId: string) {
  // Reference to the messages collection within the room
  const messagesRef = collection(firestore, 'rooms', roomId, 'messages');

  // Query to filter messages based on the idTag
  const q = query(messagesRef, where('imageUrl', '!=', ''));

  try {
    // Fetching messages that match the query
    const querySnapshot = await getDocs(q);

    // Extracting messages from the query snapshot
    const messages = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return messages;
  } catch (error) {
    console.error('Error fetching messages:', error);
    throw error;
  }
}

export async function getMessageFromMessage(roomId: string, message: IMessage): Promise<number | null> {
  const messagesCollection = collection(firestore, `rooms/${roomId}/messages`);

  try {
    const q = query(messagesCollection, orderBy('createdAt'), where('createdAt', '>=', message.createdAt));
    const snapshot = await getDocs(q);

    const messageCount: number = snapshot.docs.length;
    return messageCount;
  } catch (error) {
    if (error.name === 'AbortError') {
      console.log('Operation aborted.');
    } else {
      console.error('Error fetching data:', error.message);
    }
    return null;
  }
}
export async function deleteAllMessagesInRoom(roomId: string) {
  const messagesRef = collection(firestore, `rooms/${roomId}/messages`);
  const snapshot = await getDocs(messagesRef);
  const batch = writeBatch(firestore);

  snapshot.docs.forEach((doc) => {
    batch.delete(doc.ref);
  });

  await batch.commit();
  console.log('All messages deleted');
}

export async function updateChatMessage(roomId: string, messageId: string, newMessage: IMessage) {
  try {
    // Get a reference to the room

    const messagesCollectionRef = collection(doc(firestore, 'rooms', roomId), 'messages');
    const messageRef = doc(messagesCollectionRef, messageId);
    await updateDoc(messageRef, {
      ...newMessage,
      updatedAt: new Date(),
    });
  } catch (error) {
    console.error('Error updating document: ', error);
  }
}
export async function deleteMessage(roomId: string, messageId: string) {
  try {
    // Get a reference to the room
    const roomRef = doc(firestore, 'rooms', roomId);

    // Reference the message document to be deleted
    const messageRef = doc(collection(roomRef, 'messages'), messageId);

    // Delete the message document
    await deleteDoc(messageRef);

    console.log('Message deleted successfully.');
  } catch (error) {
    console.error('Error deleting message: ', error);
  }
}

// Function to get messages collection
export function getMessagesCollection(roomId: string) {
  // Get a reference to the room's messages collection ordered by createdAt
  const roomRef = collection(doc(firestore, 'rooms', roomId), 'messages');
  const q = query(roomRef, orderBy('createdAt'));
  return q;
}


export async function getMessageById(roomId: string, messageId: string): Promise<IMessage | null> {
  try {
    // Get a reference to the specific message document
    const messageRef = doc(firestore, 'rooms', roomId, 'messages', messageId);

    // Fetch the document data
    const docSnap = await getDoc(messageRef);

    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() } as IMessage;
    } else {
      console.log('No such document!');
      return null;
    }
  } catch (error) {
    console.error('Error getting document:', error);
    return null;
  }
}
