export const WelcomeMessage = `Welcome to Sonatico!

We are happy to introduce you to our platform. Our goal is to improve communication between teachers and students. 
In this chat, you can make notes, save homework, make bookmarks, start lessons, and much more.

Here’s how to get started:

- Click the "Add a New User" icon at the top-left and send the link to the new user.
- After the new user follows the link and appears in the list on the left side, you can start a conversation.
- If you are ready to start a lesson, choose the user you want to contact and press the "Start a Lesson" button to connect.

Make sure you have allowed browser permissions for your microphone and speakers. You can adjust settings while connected to the call.
If you have any questions, feel free to ask in this chat.

Happy classes!

This video will guide you through the basics to get you started:
https://www.youtube.com/watch?v=9GMztd2GLjw`;
