import { Box, Button, Dialog, Typography } from '@mui/material';
import React from 'react';
import { Title } from './Typography';
import { ProfileInput } from 'components/ProfileInput/ProfileInput';
import { ReactComponent as UserIcon } from 'assets/profileIcons/fi_user.svg';
import { ReactComponent as MailIcon } from 'assets/profileIcons/fi_mail.svg';
import { useForm } from 'react-hook-form';
import { ModalHeader } from './ModalHeader';
import { saveUser } from 'firebase-db/UserService';
import { Timestamp } from '@firebase/firestore';
import * as yup from 'yup';
import { ValidationErrorsContainer } from 'containers/ValidationErrors/ValidationErrorsContainer';
import { ErrorMessage } from 'components/ErrorMessage/ErrorMessage';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup.object({
  displayName: yup.string().required('This field is required').min(2, 'Name must be at least 2 characters'),
  email: yup.string().email('Email must be valid'),
  description: yup.string().optional(),
});

export function CommentModal({ open, setOpen, user, currentUser, comments, setComments }) {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  function onSubmit(data) {
    const newValues = {
      ...user.getData(),
      comments: [
        ...user.getData().comments,
        {
          date: Timestamp.fromDate(new Date()),
          comment: data?.description || '',
          from: {
            id: currentUser?.id,
            photo: currentUser?.photoURL || null,
            email: data.email || '',
            name: data.displayName,
          },
        },
      ],
    };

    saveUser(newValues, { merge: false })
      .then(() => {
        setComments([
          ...comments,
          {
            date: Timestamp.fromDate(new Date()),
            comment: data?.description || '',
            from: {
              id: currentUser?.id,
              photo: currentUser?.photoURL || null,
              email: data.email || '',
              name: data.displayName,
            },
          },
        ]);
      })
      .catch(console.error);
    handleClose();
    reset();
  }

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <ModalHeader handleClose={handleClose} title="Leave a comment"></ModalHeader>
      <Box sx={{ padding: '0px 24px 16px 24px', backgroundColor: '#FCFCFD' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Title>Leave a comment</Title>
          <Typography sx={{ color: '#667085', fontSize: '14px', fontWeight: 500 }}>
            Your email address not be a published. Required fields are marked
            <span style={{ color: '#452D9E' }}>*</span>
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ display: 'flex', gap: '16px', marginBottom: '16px' }}>
              <ValidationErrorsContainer>
                <ProfileInput
                  errors={errors.displayName ? true : false}
                  required={true}
                  rules={{ required: 'This field is required' }}
                  startIcon={<UserIcon />}
                  control={control}
                  placeholder="Enter your first name"
                  label="First name"
                  name="displayName"
                />
                {errors?.displayName && <ErrorMessage>{errors.displayName?.message}</ErrorMessage>}
              </ValidationErrorsContainer>
              <ValidationErrorsContainer>
                <ProfileInput
                  errors={errors.email ? true : false}
                  startIcon={<MailIcon />}
                  placeholder="Enter your email"
                  label="Email"
                  control={control}
                  name="email"
                />
                {errors?.email && <ErrorMessage>{errors.email?.message}</ErrorMessage>}
              </ValidationErrorsContainer>
            </Box>
            <Box sx={{ marginBottom: '24px' }}>
              <ValidationErrorsContainer>
                <ProfileInput
                  errors={errors.description ? true : false}
                  placeholder="Enter your description"
                  label="Description"
                  control={control}
                  name="description"
                  multiline
                  rows={5}
                />
                {errors?.description && <ErrorMessage>{errors.description?.message}</ErrorMessage>}
              </ValidationErrorsContainer>
            </Box>
            <Button
              type="submit"
              variant="contained"
              sx={{
                fontSize: '18px',
                fontWeight: 600,
                textTransform: 'capitalize',
                backgroundColor: '#452D9E',
                width: '100%',
              }}>
              Save
            </Button>
          </form>
        </Box>
      </Box>
    </Dialog>
  );
}
