const routeNames = {
  landing: '/',
  home: '/home',
  teacherProfile: '/teacher-profile',
  studentProfile: '/student-profile',
  teachers: '/teachers',
  settingsProfile: '/settings',
  successLessonRequestPage: '/success-lesson-request',
  dashboard: '/dashboard',
  event: '/event',
  eventInfo: '/event-info',
  chat: '/chat',
  chatRoom: '/chat/:roomId',

  profile: '/profile',
  contact: '/contact',
  signIn: '/sign-in',
  resetPassword: '/reset-password',
  signUp: '/sign-up',
  room: '/room',
  wrongRoom: '/wrong-room',
  lesson: '/lesson',
  join: '/join',
  privacy: '/privacy',
  registerEvent: '/event-info/register',
  eventVideoCall: '/event-video-call',

  admin: '/a-panel',
  stat: '/a-panel/stat',
  adminSignals: '/admin-signals',
  adminSignalStat: '/admin-signal-stat',

  teacherSearch: '/teachersearch',
};

export default routeNames;
