export const musicalInstruments = {
  accordion: 'Accordion',
  bassoon: 'Bassoon',
  cello: 'Cello',
  clarinet: 'Clarinet',
  composition: 'Composition',
  conducting: 'Conducting',
  double_bass: 'Double Bass',
  drums: 'Drums',
  euphonium: 'Euphonium',
  flute: 'Flute',
  guitar: 'Guitar',
  harmony: 'Harmony',
  harp: 'Harp',
  oboe: 'Oboe',
  piano: 'Piano',
  recorder: 'Recorder',
  saxophone: 'Saxophone',
  theory: 'Theory',
  trombone: 'Trombone',
  trumpet: 'Trumpet',
  tube: 'Tube',
  ukulele: 'Ukulele',
  viola: 'Viola',
  violin: 'Violin',
  vocal: 'Vocal',
  other: 'Other',
};

export const instrumentList = Object.keys(musicalInstruments).map((instr) => ({ name: instr }));
export const profileInstrumentList = Object.keys(musicalInstruments);
