import { Avatar, Box, Link, Typography } from '@mui/material';
import React, { useState } from 'react';
import { SubDescription, Title } from './Typography';
import { ProfilePageComponentWrapper } from '../ProfilePageComponentWrapper';
import Lightbox from 'yet-another-react-lightbox/*';

export function StudentShowcaseSection({
  data,
}: {
  data: {
    label?: string;
    subLabel?: string;
    link?: string;
    images?: string[];
  }[];
}) {
  return (
    <ProfilePageComponentWrapper>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <Title>Success showcase</Title>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
          {data?.map((item) => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',

                gap: '12px',
              }}>
              <Avatar
                sx={{
                  width: '64px',
                  height: '64px',
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}>
                <Box
                  sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                    }}>
                    <Typography
                      sx={{
                        color: '#101828',
                        fontWeight: '600',
                      }}>
                      {item.label}
                    </Typography>
                    <SubDescription>{item.subLabel}</SubDescription>
                    {item.link && (
                      <Link
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          color: '#452D9E',
                          fontSize: '14px',
                          fontWeight: 500,
                        }}>
                        {item.link}
                      </Link>
                    )}
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(5,1fr)',
                        gap: '16px',
                      }}>
                      {item.images?.map((item, index) => {
                        if (index > 4) return null;

                        return (
                          <Box
                            sx={{ position: 'relative', height: '120px', cursor: 'pointer' }}
                            //  onClick={() => setIsOpen(true)}
                          >
                            <img
                              key={`${item}-${index}`}
                              style={{
                                objectFit: 'cover',

                                height: '120px',
                                borderRadius: '8px',
                                filter: index === 4 ? 'brightness(60%)' : 'brightness(100%)',
                              }}
                              src={item}
                              alt="studio"
                            />
                            {index === 4 && (
                              <Box
                                sx={{
                                  position: 'absolute',
                                  top: '42px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  flexDirection: 'row',
                                  width: '100%',
                                }}>
                                <Typography
                                  sx={{
                                    marginX: 'auto',
                                    color: 'white',
                                    fontSize: '24px',
                                    fontWeight: '500',
                                  }}>
                                  +5
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </ProfilePageComponentWrapper>
  );
}
