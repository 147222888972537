import { ProfileHeader } from 'containers/SettingsProfilePage/components/ProfileHeader';
import React, { useEffect, useState } from 'react';
import { ProfileInfo } from './components/ProfileInfo';
import { useAuth } from 'hooks/useAuth';
import { ContactInfo } from './components/ContactInfo';
import { StudentSpecializedSection } from './components/StudentSpecializedSection';
import { ProfileAboutUs } from './components/ProfileAboutUs';
import { musicalInstruments } from 'containers/SettingsPage/Teacher/instruments';
import { ProfilePageComponentWrapper } from './ProfilePageComponentWrapper';
import { ProfileReusableInfoCard } from './components/ProfileReusableInfoCard';
import { NotesSection } from './components/NotesSection';
import { StudentShowcaseSection } from './components/StudentShowcaseSection';

import { getUserById } from 'firebase-db/UserService';
import { useLocation, useHistory } from 'react-router-dom';
import { Roles, User } from 'models/User.Class';
import { Button, Box } from 'components/basic';
import { EditIcon } from 'components/icons';
import routeNames from 'route-names';

export function StudentProfilePage() {
  const auth = useAuth();
  const currentUser: User = auth.user.model;
  const [user, setUser] = useState<User | null>();
  const location = useLocation();
  const history = useHistory();
  const languagesTags = user?.teacher?.teacherPreferences?.map((item) => musicalInstruments[item?.name]) || [];
  const instrumentTags = user?.languages?.map((item) => item?.name) || [];
  const skillsTags = user?.skills || [];
  const showCase = user?.showCase?.map((item) => ({
    label: item.showCaseTitle,
    link: item.showCaseUrl,
    images: item.showCaseImages,
  }));

  const pathname = location.pathname;
  const segments = pathname.split('/');
  const lastSegment = segments[segments.length - 1];

  useEffect(() => {
    getUserById(lastSegment)
      .then((item) => {
        setUser(item);
        if (item?.isTeacher()) history.push('/not-found');
      })
      .catch((reason) => {
        if (reason) {
          history.push('/not-found');
        }
      });
  }, []);

  const birthDate = user?.dateBirth || null;
  const age = calculateAge(birthDate);
  const description = `${user?.gender && user?.gender !== 'choose' ? user?.gender : ''}${
    user?.gender && user?.gender !== 'choose' && age !== null && age !== 0 ? ', ' : ''
  } ${age === null || age === 0 ? 'Age unknown' : `${age} years old`}`;
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', marginX: -2 }}>
      <ProfileHeader
        label="Student Profile"
        handleClickBack={() => {
          if (history.length > 1) {
            history.goBack();
          }
        }}
        rightButton={
          currentUser?.id === user?.id && (
            <Button variant="outlined" onClick={() => history.push(`${routeNames.settingsProfile}`)}>
              <EditIcon />
              &nbsp;&nbsp;Edit
            </Button>
          )
        }
      />
      <Box
        sx={{
          display: { xs: 'flex', md: 'grid' },
          flexDirection: 'column',
          gridTemplateColumns: '1fr 1.6fr',
          padding: { xs: '12px', md: '24px', lg: '32px' },
          gap: { xs: '16px', md: '24px' },
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
          }}>
          <ProfileInfo
            description={description}
            isOwner={false}
            name={user?.displayName}
            surname={user?.displaySurname}
            photo={user?.photoURL}
            languages={user?.languages?.map((item) => item?.name)}
          />
          <ContactInfo
            user={user}
            website={''}
            email={user?.email}
            location={user?.location?.formatted_address}
            socialMedia={user?.socialLinks?.map((item) => item?.url)}
          />
          {user?.learningPreferences?.[0]?.name && (
            <StudentSpecializedSection specializations={user?.learningPreferences} />
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '32px',
            }}>
            {user?.about && (
              <ProfileAboutUs tags={[...skillsTags, ...languagesTags, ...instrumentTags]} about={user?.about} />
            )}
            {user?.showCase?.length > 0 && <StudentShowcaseSection data={showCase}></StudentShowcaseSection>}
            {user?.roles?.includes(Roles.Teacher) && <NotesSection user={user} />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export function calculateAge(birthDate) {
  if (!birthDate) return null;
  const today = new Date();
  const birthDateYear = birthDate.getFullYear();
  const birthDateMonth = birthDate.getMonth();
  const birthDateDay = birthDate.getDate();

  let age = today.getFullYear() - birthDateYear;
  const m = today.getMonth() - birthDateMonth;
  if (m < 0 || (m === 0 && today.getDate() < birthDateDay)) {
    age--;
  }
  return age;
}
