import React from 'react';
import { ProfilePageComponentWrapper } from '../ProfilePageComponentWrapper';
import { Description, Subtitle, Title } from './Typography';
import { Box } from '@mui/material';

export function InfoSection({ teachingStyle, startedTeaching, isStyleVisible, isYearVisible }) {
  return (
    <ProfilePageComponentWrapper>
      <Box display="flex" flexDirection="column" sx={{ gap: { xs: '16px', md: '24px' } }}>
        <Title>Info</Title>
        {teachingStyle && isStyleVisible && (
          <Box display="flex" flexDirection="column" gap="12px">
            <Subtitle subtitle="Teaching style"></Subtitle>
            <Description description={teachingStyle}></Description>
          </Box>
        )}
        {startedTeaching && isYearVisible && (
          <Box display="flex" gap="4px" alignItems="center">
            <Subtitle subtitle="Started teaching in"></Subtitle>
            <Description description={startedTeaching}></Description>
          </Box>
        )}
      </Box>
    </ProfilePageComponentWrapper>
  );
}
