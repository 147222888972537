// Importing necessary functions and features from Firebase 9
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  confirmPasswordReset,
  updatePassword,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  onAuthStateChanged,
} from 'firebase/auth';
import { auth } from './firebase'; // Ensure 'auth' is correctly initialized in your 'firebase.js' file

// Sign Up
export const doCreateUserWithEmailAndPassword = async (email, password) => {
  try {
    await createUserWithEmailAndPassword(auth, email, password);
  } catch (e) {
    throw new Error(e);
  }
};

// Sign In
export const doSignInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (e) {
    throw new Error(e);
  }
};

// Sign out
export const doSignOut = () => signOut(auth);

// Password Reset
export const doPasswordReset = (email) => sendPasswordResetEmail(auth, email);

export const doConfirmPasswordReset = (code, password) => confirmPasswordReset(auth, code, password);

// Password Change
export const doPasswordUpdate = (password) => {
  if (auth.currentUser) {
    return updatePassword(auth.currentUser, password);
  } else {
    throw new Error('No authenticated user');
  }
};

// Sign In With Google
export const doSignInWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  return signInWithPopup(auth, provider);
};

export const doSignInWithFacebook = () => {
  const provider = new FacebookAuthProvider();
  return signInWithPopup(auth, provider);
};

// Auth State Changed
export const onAuthStateChangedWrapper = (f) => onAuthStateChanged(auth, f);
