import { collection, query, where } from 'firebase/firestore';

export const snapshotToArray = (snapshot): any[] => {
  try {
    if (snapshot.empty) {
      console.log('No matching documents.');
      return [];
    }

    if (snapshot.docs) {
      return Array.prototype.map.call(snapshot.docs, (doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    }
  } catch (error) {
    console.error('snapshotToArray', error);
  }
  return [];
};

export function createQuery(db, collectionName, conditions) {
  const colRef = collection(db, collectionName);

  if (conditions.length === 0) {
    return colRef; // Return the CollectionReference if there are no conditions
  }

  let q = query(colRef); // Start with an initial query

  conditions.forEach((condition) => {
    q = query(q, where(condition.field, condition.operator, condition.value));
  });

  return q;
}
