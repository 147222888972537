import LoadingIndicator from 'components/LoadingIndicator';
import loadable from 'helpers/loadable';
import React from 'react';
import { Roles } from 'models/User.Class';

/**
 * Asynchronously loads the component for HomePage
 */
export default loadable(() => import('./EventVideoCallPage'), {
  fallback: <LoadingIndicator />,
});
