import {
  getFirestore,
  collection,
  doc,
  query,
  where,
  getDocs,
  addDoc,
  deleteDoc,
  setDoc,
  getDoc,
  updateDoc,
} from 'firebase/firestore';
import { firestore } from './firebase';
import { v4 as uuidv4 } from 'uuid';
import { snapshotToArray } from './fb-helpers';
import { addEventInviteToEvent, IEventParticipant } from './EventService';
export async function createEventInvite(invite: Omit<IEventInvite, 'id'>) {
  const newEventInviteId = uuidv4();
  const eventInviteRef = doc(firestore, 'eventInvite', newEventInviteId);

  const eventData: IEventInvite = {
    ...invite,
    id: newEventInviteId,
  };
  await addEventInviteToEvent(invite.eventId, newEventInviteId);
  await setDoc(eventInviteRef, eventData);
  return eventData;
}
export async function deleteEventInvite(inviteId: string) {
  return deleteDoc(doc(firestore, 'eventInvite', inviteId));
}
export async function getUserEventInvites(senderId: string) {
  try {
    const q = query(collection(firestore, 'eventInvite'), where('senderId', '==', senderId));
    const querySnapshot = await getDocs(q); // Getting documents based on the query
    return snapshotToArray(querySnapshot); // Convert snapshot to array using the provided helper function
  } catch (error) {
    console.error('Error fetching user invites:', error);
    return [];
  }
}
export async function getEventInviteById(inviteId) {
  const docRef = doc(firestore, 'eventInvite', inviteId);
  const docSnap = await getDoc(docRef); // Get a document snapshot
  return docSnap.exists() ? docSnap.data() : null; // Return data if document exists, otherwise null
}

export const addOrUpdateParticipantInEvent = async (eventId: string, infoUser: IEventParticipant) => {
  const eventRef = doc(firestore, 'events', eventId);
  const eventDoc = await getDoc(eventRef);
  const newParticipiant = { ...infoUser };
  const event = eventDoc.data();

  if (event && newParticipiant?.id) {
    event.participantIds = event.participantIds || [];
    if (!event?.participantIds?.includes(infoUser.id)) {
      event.participantIds = [...event?.participantIds, infoUser.id];
    }
    if (
      event?.participants?.length > 0 &&
      !event?.participants?.find((participant) => participant.id === infoUser.id)
    ) {
      event.participants = [...event?.participants, newParticipiant];
    } else if (event?.participants?.length === 0) {
      event.participants = [newParticipiant];
    } else {
      event.participants = event.participants.map((participant) => {
        if (participant.id === infoUser.id) {
          return newParticipiant;
        }
        return participant;
      });
    }
    await setDoc(eventRef, event);
  }
};

export const updateStatusParticipant = async (eventId: string, infoUser) => {
  const eventRef = doc(firestore, 'events', eventId);
  const eventDoc = await getDoc(eventRef);
  await updateDoc(eventRef, {
    participants: [
      ...eventDoc.data().participants.map((item) => {
        if (item.id === infoUser.id) {
          return { ...infoUser };
        }
        return item;
      }),
    ],
  });
};

export const removeParticipantFromEvent = async (eventId: string, participantId: string) => {
  const eventRef = doc(firestore, 'events', eventId);
  const event = (await getDoc(eventRef)).data();
  const dataEvent = {
    ...event,
    participants: event.participants.map((eventParticipant) => {
      if (eventParticipant.id === participantId) {
        return {
          ...eventParticipant,
          status: 'rejected',
        };
      }
      return eventParticipant;
    }),
  };
  await setDoc(eventRef, dataEvent);
};

export const setRoleForParticipant = async (
  eventId: string,
  newRole: 'participant' | 'moderator' | 'judge',
  userId: string
) => {
  const eventRef = doc(firestore, 'events', eventId);
  const eventInvite = await getDoc(eventRef);

  await updateDoc(eventRef, {
    ...eventInvite.data(),
    participants: [
      ...eventInvite.data().participants.map((item) => {
        if (item.id === userId) {
          return {
            ...item,
            role: newRole,
          };
        }
        return item;
      }),
    ],
  });
};

export interface IEventInvite {
  eventId: string;
  senderId: string;
  id: string;
}
