import React, { useEffect, useState } from 'react';

import { ProfileAboutUs } from './components/ProfileAboutUs';
import { ProfileRatingSystem } from './components/ProfileRatingSystem';

import { ProfilePageComponentWrapper } from './ProfilePageComponentWrapper';
import { ProfileInfo } from './components/ProfileInfo';
import { useAuth } from 'hooks/useAuth';
import { User } from 'models/User.Class';
import { musicalInstruments } from 'containers/SettingsPage/Teacher/instruments';
import { ProfileReviews } from './components/ProfileReviews';

import { ContactInfo } from './components/ContactInfo';
import { SpecializedSection } from './components/SpecializedSection';
import { ProfileReusableInfoCard } from './components/ProfileReusableInfoCard';
import { ProfileStudio } from './components/ProfileStudio';
import { ProfileHeader } from 'containers/SettingsProfilePage/components/ProfileHeader';
import { format } from 'date-fns';
import { useHistory, useLocation } from 'react-router-dom';
import { getUserById } from 'firebase-db/UserService';
import LoadingIndicator from 'components/LoadingIndicator';
import { InfoSection } from './components/InfoSection';
import { calculateAge } from './StudentProfilePage';
import routeNames from 'route-names';
import { Button, Box } from 'components/basic';
import { EditIcon } from 'components/icons';
import { Timestamp } from 'firebase/firestore';

export default function TeacherProfilePage() {
  const [user, setUser] = useState<User | null>();
  const location = useLocation();
  const history = useHistory();
  const pathname = location.pathname;
  const segments = pathname.split('/');
  const userId = segments[segments?.length - 1];

  useEffect(() => {
    getUserById(userId)
      .then((item) => {
        setUser(item);
        if (!item?.isTeacher()) history.push('/not-found');
      })
      .catch((reason) => {
        if (reason) {
          history.push('/not-found');
        }
      });
  }, []);

  const auth = useAuth();
  const currentUser: User = auth.user.model;

  const languagesTags = user?.teacher?.teacherPreferences?.map((item) => musicalInstruments[item?.name]) || [];
  const instrumentTags = user?.languages?.map((item) => item?.name) || [];
  const skillsTags = user?.skills || [];
  const experience = user?.teacher?.experience?.map((item) => ({
    label: item?.companyName,
    subLabel: item?.position,
    downLabel:
      item?.dateRange?.from instanceof Timestamp && item?.dateRange?.to instanceof Timestamp
        ? formatRange(item?.dateRange?.from, item?.dateRange?.to)
        : `${item?.dateRange?.from} - ${item?.dateRange?.to === 'current' ? 'Now' : item?.dateRange?.to}`,
    link: item?.experienceLink,
    additionalLabel: 'Visit website',
  }));

  const affiliations = user?.teacher?.qualification?.affiliations?.map((item) => ({
    label: item?.affliationSchool,
    subLabel: `${item?.affliationDegree} ${item?.affliationDegree && item?.affliationDesignation ? ',' : ''} ${
      item?.affliationDesignation
    }`,
    downLabel:
      item?.affliationYear?.from instanceof Timestamp && item?.affliationYear?.to instanceof Timestamp
        ? formatRange(item?.affliationYear?.from, item?.affliationYear?.to)
        : `${item?.affliationYear?.from} - ${
            item?.affliationYear?.to === 'current' ? 'Now' : item?.affliationYear?.to
          }`,
    link: item?.affliationUrlLink,
    additionalLabel: 'Show credential',
  }));

  const development = user?.teacher?.qualification?.development?.map((item) => ({
    label: item?.developmentTitle,
    subLabel: `${item?.developmentSchool ? item?.developmentSchool : ''} ${
      item?.developmentSchool && item?.developmentDescription ? ',' : ''
    } ${item?.developmentDescription ? item?.developmentDescription : ''}`,
    downLabel:
      item?.developmentYear?.from instanceof Timestamp && item?.developmentYear?.to instanceof Timestamp
        ? formatRange(item?.developmentYear?.from, item?.developmentYear?.to)
        : `${item?.developmentYear?.from} - ${
            item?.developmentYear?.to === 'current' ? 'Now' : item?.developmentYear?.to
          }`,
    link: item?.developmentUrlLink,
    additionalLabel: 'Show credential',
  }));

  const associations = user?.teacher?.qualification?.associations?.map((item) => ({
    label: item?.associationsName,
    subLabel: `${item?.associationsSchool ? item?.associationsSchool : ''} ${
      item?.associationsSchool && item?.associationsDescription ? ',' : ''
    } ${item?.associationsDescription ? item?.associationsDescription : ''}`,
    downLabel:
      item?.associationsYear?.from === undefined && item?.associationsYear?.to === undefined
        ? item?.associationsYear
        : item?.associationsYear?.from instanceof Timestamp && item?.associationsYear?.to instanceof Timestamp
        ? formatRange(item?.associationsYear?.from, item?.associationsYear?.to)
        : `${item?.associationsYear?.from} - ${
            item?.associationsYear?.to === 'current' ? 'Now' : item?.associationsYear?.to
          }`,

    link: item?.associationsUrlLink,
    additionalLabel: 'Show credential',
  }));
  const studio = user?.teacher?.school;
  if (!user) return null;

  function formatRange(fromTimestamp, toTimestamp) {
    if (fromTimestamp === undefined && toTimestamp === undefined) {
      return '';
    }
    const fromDate = format(fromTimestamp?.seconds ? fromTimestamp?.toDate() : fromTimestamp, 'MMM yyyy');
    const toDate = format(toTimestamp?.seconds ? toTimestamp?.toDate() : toTimestamp, 'MMM yyyy');
    return `${fromDate} - ${toDate}`;
  }

  const currentExperience = user?.teacher?.experience?.find((item) => item?.dateRange?.to === 'current');
  const description = currentExperience?.position || 'Teacher';

  const birthDate =
    user?.dateBirth && user?.visibleFields?.visibleDateOfBirth
      ? typeof user.dateBirth.toDate === 'function'
        ? user.dateBirth.toDate()
        : user.dateBirth
      : null;
  const age = calculateAge(birthDate);
  const genderAge =
    `${
      user?.visibleFields?.visibleGender && user?.gender && user.gender !== 'choose' ? `Gender: ${user?.gender}` : ''
    } ${
      user?.visibleFields?.visibleGender &&
      user?.gender &&
      user?.gender !== 'choose' &&
      user?.visibleFields?.visibleDateOfBirth &&
      age
        ? ', '
        : ''
    } ${age === null || age === 0 ? '' : `${age} years old`}` || null;

  if (!user) {
    return <LoadingIndicator />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', marginX: -2 }}>
      <ProfileHeader
        label="Teacher Profile"
        handleClickBack={() => {
          if (history.length > 1) {
            history.goBack();
          }
        }}
        rightButton={
          currentUser?.id === user?.id && (
            <Button variant="outlined" onClick={() => history.push(`${routeNames.settingsProfile}`)}>
              <EditIcon />
              &nbsp;&nbsp;Edit
            </Button>
          )
        }
      />
      <Box
        sx={{
          display: { xs: 'flex', md: 'grid' },
          flexDirection: 'column',
          gridTemplateColumns: '320px 1fr',
          padding: { xs: '12px', md: '24px', lg: '32px' },
          gap: { xs: '16px', md: '24px' },
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'column' },
            flexWrap: 'wrap',
            flex: 0,
            gap: { xs: '16px', md: '24px' },
          }}>
          <ProfileInfo
            genderAge={genderAge}
            description={description}
            isOwner={false}
            name={user?.displayName}
            surname={user?.displaySurname}
            photo={user?.photoURL}
            languages={user?.languages?.map((item) => item?.name)}
          />

          {/* <ProfileRatingSystem /> */}
          <ContactInfo
            user={user}
            website={''}
            email={user?.email}
            location={user?.location?.formatted_address}
            city={user?.location?.city}
            country={user?.location?.country}
            phone={user?.phoneNumber}
            socialMedia={user?.socialLinks?.map((item) => item?.url)}
          />
          {user?.teacher?.teacherPreferences?.length > 0 && (
            <SpecializedSection specializations={user?.teacher?.teacherPreferences} />
          )}
          {(user?.teacher?.teachingStyle || user?.teacher?.startingTeaching) &&
            (user?.teacher?.visibleTeachingStyle || user?.teacher?.visibleStartingTeaching) && (
              <InfoSection
                isStyleVisible={user?.teacher?.visibleTeachingStyle}
                isYearVisible={user?.teacher?.visibleStartingTeaching}
                teachingStyle={user?.teacher?.teachingStyle}
                startedTeaching={user?.teacher?.startingTeaching}
              />
            )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: '16px', md: '24px' },
          }}>
          {user?.teacher?.about && (
            <ProfileAboutUs tags={[...skillsTags, ...instrumentTags, ...languagesTags]} about={user?.teacher?.about} />
          )}
          {(affiliations?.length > 0 || development?.length > 0) && (
            <ProfilePageComponentWrapper>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <ProfileReusableInfoCard title="Credentials & Affiliations" data={affiliations} />
                <Box sx={{ height: 1, flex: 1, my: 3, borderBottom: '1px solid rgba(0,0,0,0.05)' }} />
                <ProfileReusableInfoCard title="Professional Development" data={development} />
              </Box>
            </ProfilePageComponentWrapper>
          )}
          {associations?.length > 0 && (
            <ProfilePageComponentWrapper>
              <ProfileReusableInfoCard
                title="Awards, Distinctions, Associations & Memberships Showcase "
                data={associations}
              />
            </ProfilePageComponentWrapper>
          )}
          {experience?.length > 0 && (
            <ProfilePageComponentWrapper>
              <ProfileReusableInfoCard title="Experience" data={experience} />
            </ProfilePageComponentWrapper>
          )}
          {(studio?.schoolDescription || studio?.website || studio?.files?.length > 0) && (
            <ProfileStudio
              images={studio?.files}
              website={studio?.website}
              location={studio?.address}
              label={studio?.schoolDescription}
            />
          )}
          <ProfileReviews user={user} currentUser={currentUser}></ProfileReviews>
        </Box>
      </Box>
    </Box>
  );
}
