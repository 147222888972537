import { Box, Typography } from '@mui/material';
import React from 'react';
import { ProfilePageComponentWrapper } from '../ProfilePageComponentWrapper';
import { TagEllipsis } from 'containers/SettingsProfilePage/components/TagEllipsis';
import { Title } from './Typography';

export function ProfileAboutUs({ about, tags }: { about?: string; tags: string[] }) {
  return (
    <ProfilePageComponentWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: '16px', md: '24px' },
        }}>
        <Title>About me</Title>
        <Typography sx={{ color: '#667085', fontSize: '14px', whiteSpace: 'break-spaces' }} component={'pre'}>
          {about}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '12px',
          }}>
          {tags.map((item, index) => (
            // <AboutTag title={item} key={index} />
            <TagEllipsis key={index} maxWidth={150} data={item} index={index}></TagEllipsis>
          ))}
        </Box>
      </Box>
    </ProfilePageComponentWrapper>
  );
}

export function AboutTag({ title }: { title: string }) {
  return (
    <Box
      sx={{
        borderRadius: '100px',
        bgcolor: '#F4F3FF',
        border: ' 1px solid #3042ba',

        padding: { xs: '6px', lg: '8px' },
      }}>
      <Typography sx={{ fontSize: { xs: '13px', md: '14px' }, color: '#3042ba' }}>{title}</Typography>
    </Box>
  );
}
