import * as React from 'react';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export function ProfileProgress(props: CircularProgressProps & { value: number }) {
  const getColor = (value: number) => {
    if (value === 100) {
      return '#4caf50';
    } else if (value >= 20) {
      return '#ff9800';
    } else if (value < 20) {
      return '#f44336';
    }
  };

  const customColor = getColor(props.value);

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: customColor,
        }}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Typography variant="caption" component="div">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
