import { getFirestore, collection, addDoc, query, where, orderBy, limit, getDocs } from 'firebase/firestore';

import { snapshotToArray } from './fb-helpers';
import { firebase } from './firebase';

const firestore = getFirestore(firebase); // Initialize Firestore with your Firebase app

export function saveUserAction(info) {
  return addDoc(collection(firestore, 'actions'), { ...info, date: new Date().toISOString() });
}

export async function getUserActions({ limit = 200, orderBy = 'date', queries }) {
  let firestoreQuery:any = collection(firestore, 'actions');
  
  if (queries) {
    queries.forEach((where) => {
      firestoreQuery = query(firestoreQuery, where.key, where.operator, where.value);
    });
  }
  
  firestoreQuery.orderBy(firestoreQuery, orderBy, 'desc');
  firestoreQuery.limit(firestoreQuery, limit);

  const querySnapshot = await getDocs(firestoreQuery);

  return snapshotToArray(querySnapshot);
}
