
import { getFirestore, collection, doc, query, where, getDocs, addDoc, deleteDoc, setDoc, getDoc } from 'firebase/firestore';
import { snapshotToArray } from './fb-helpers';
import { firestore } from './firebase';




export function saveInvite(id, data) {
  return setDoc(doc(firestore, 'invite', id), { ...data }); // Using setDoc for setting document data
}

export async function getPersonalInvites(userId): Promise<any[]> {
  try {
    const q = query(collection(firestore, 'invite'), where('personal', '==', true), where('userId', '==', userId));
    const querySnapshot = await getDocs(q); // Getting documents based on the query
    if (!querySnapshot.empty) {
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    }
    return [];
  } catch (error) {
    console.error('Error fetching personal invites:', error);
    return [];
  }
}

export async function getInviteById(inviteId) {
  const docRef = doc(firestore, 'invite', inviteId);
  const docSnap = await getDoc(docRef); // Get a document snapshot
  return docSnap.exists() ? docSnap.data() : null; // Return data if document exists, otherwise null
}

export async function createInvite(invite) {
  return addDoc(collection(firestore, 'invite'), { ...invite, createdAt: new Date().toISOString() }); // Using addDoc to add a new document
}

export async function deleteInvite(inviteId) {
  return deleteDoc(doc(firestore, 'invite', inviteId)); // Using deleteDoc to delete a document
}

export async function getUserInvites(userId) {
  try {
    const q = query(collection(firestore, 'invite'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q); // Getting documents based on the query
    return snapshotToArray(querySnapshot); // Convert snapshot to array using the provided helper function
  } catch (error) {
    console.error('Error fetching user invites:', error);
    return [];
  }
}
