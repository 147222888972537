import React, { useState } from 'react';
import { H1 } from 'components/H1';
import { Button, TextField, Grid } from 'components/basic';
import { useRequireNoAuth } from 'hooks/useRequireNoAuth';

const btnMinWidth = '300px';

const PasswordResetPage = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const auth = useRequireNoAuth();

  const onSubmit = (event) => {
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        setSuccess(true);
        setError(null);
      })
      .catch((error) => {
        setError(error);
      });

    event.preventDefault();
  };

  const isInvalid = email === '';

  return (
    <div style={{ margin: '0 auto', maxWidth: btnMinWidth }}>
      <H1 align="center">Reset Password</H1>
      <Grid item xs={12}>
        {error && <p style={{ color: 'red' }}>{error.message}</p>}
        {success && <p style={{ color: 'green' }}>Password reset email sent!</p>}
        <form onSubmit={onSubmit}>
          <TextField
            fullWidth
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            type="text"
            label="Email Address"
            margin="dense"
          />
          <Button style={{ width: '100%' }} color="primary" variant="contained" disabled={isInvalid} type="submit">
            Send Password Reset Email
          </Button>
        </form>
      </Grid>
    </div>
  );
};

export default PasswordResetPage;
