import React from 'react';
import { Box, Link } from '@mui/material';
import XIcon from '@mui/icons-material/X';
import { Description, Subtitle, Title } from './Typography';
import { ProfilePageComponentWrapper } from '../ProfilePageComponentWrapper';
import { ReactComponent as FacebookIcon } from 'assets/facebook.svg';
import InstagramIcon from '@mui/icons-material/Instagram';
import { checkSocialLink } from 'helpers/socialLinks';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TelegramIcon from '@mui/icons-material/Telegram';
import LinkIcon from '@mui/icons-material/Link';
import { User } from 'models/User.Class';

interface ContactInfoProps {
  location?: string;
  city?: string;
  country?: string;
  phone?: string;
  email?: string;
  website?: string;
  socialMedia?: string[];
  user: User;
}

interface ContactInfoDetailsProps {
  contactInfoDetailsTitle: string;
  contactInfoDetailsDescription: string;
}

export function ContactInfo({ location, email, website, socialMedia, user, city, country, phone }: ContactInfoProps) {
  function chooseIcon(link) {
    const socialMedia = checkSocialLink(link);
    switch (socialMedia) {
      case 'Twitter':
        return <XIcon sx={{ color: '#000', height: '14px' }} />;
      case 'Facebook':
        return <FacebookIcon style={{ height: '14px' }} />;
      case 'Instagram':
        return <InstagramIcon sx={{ color: '#000', height: '18px' }} />;
      case 'Youtube':
        return <YouTubeIcon sx={{ color: '#000', height: '18px' }} />;
      case 'Telegram':
        return <TelegramIcon sx={{ color: '#000', height: '18px' }} />;
      default:
        return <LinkIcon sx={{ color: '#000', height: '18px' }} />;
    }
  }

  const isTeacher = user?.isTeacher();

  return (
    <ProfilePageComponentWrapper>
      <Box display="flex" flexDirection="column" sx={{ gap: { xs: '16px', md: '24px' } }}>
        <Title>Contact Info</Title>
        {((user?.visibleFields?.visibleCity && user?.visibleFields?.visibleCountry && isTeacher && city && country) ||
          (!isTeacher && location)) && (
          <ContactInfoDetails contactInfoDetailsTitle="Location" contactInfoDetailsDescription={location} />
        )}
        {isTeacher && user?.visibleFields?.visibleCity && !user?.visibleFields?.visibleCountry && city && (
          <ContactInfoDetails contactInfoDetailsTitle="Location" contactInfoDetailsDescription={city} />
        )}
        {isTeacher && user?.visibleFields?.visibleCountry && !user?.visibleFields?.visibleCity && country && (
          <ContactInfoDetails contactInfoDetailsTitle="Location" contactInfoDetailsDescription={country} />
        )}
        {((user?.visibleFields?.visibleEmail && user?.email) || (!isTeacher && user?.email)) && (
          <ContactInfoDetails contactInfoDetailsTitle="Email" contactInfoDetailsDescription={email} />
        )}
        {user?.socialLinks?.length > 0 && socialMedia[0] && (
          <ContactInfoDetails contactInfoDetailsTitle="Website, media links" contactInfoDetailsDescription={website} />
        )}
      </Box>

      {socialMedia?.length > 0 && socialMedia[0] && (
        <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
          {socialMedia?.map((item, index) => (
            <Box
              key={index}
              sx={{
                borderRadius: '100%',
                border: '1px solid #99999933',
                width: '40px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                aspectRatio: '1/1',

                justifyContent: 'center',
              }}>
              <SocialMediaLink link={item}>{chooseIcon(item)}</SocialMediaLink>
            </Box>
          ))}
        </Box>
      )}
    </ProfilePageComponentWrapper>
  );
}

export function ContactInfoDetails({
  contactInfoDetailsTitle,
  contactInfoDetailsDescription,
}: ContactInfoDetailsProps) {
  return (
    <Box display="flex" flexDirection="column" gap="12px">
      <Subtitle subtitle={contactInfoDetailsTitle}></Subtitle>
      <Description description={contactInfoDetailsDescription} />
    </Box>
  );
}

export function SocialMediaLink({ link, children }) {
  return (
    <Link
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', aspectRatio: '1/1' }}
      href={link}
      rel="noopener noreferrer"
      target="_blank">
      {children}
    </Link>
  );
}
