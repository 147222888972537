import { Box, Typography } from '@mui/material';
import React from 'react';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import { ProfilePageComponentWrapper } from '../ProfilePageComponentWrapper';
import { Description, Subtitle, Title } from './Typography';
import { ReactComponent as NoteIcon } from 'assets/profileIcons/u_clipboard-notes.svg';

export function NotesSection({ user }) {
  return (
    <ProfilePageComponentWrapper>
      <Box sx={{ marginBottom: '24px' }}>
        <Title>Notes</Title>
      </Box>
      <Box sx={{ display: 'flex', gap: '8px', marginBottom: '12px' }}>
        <NoteIcon />
        <Subtitle subtitle="Private Notes"></Subtitle>
      </Box>
      {user?.teacher?.notes?.map((note) => (
        <Box>
          <Box sx={{ marginBottom: '8px' }}>
            <Description description={note.title}></Description>
          </Box>
          <Typography
            variant="subtitle2"
            sx={{ color: '#101828', fontSize: '14px', fontWeight: 500, marginBottom: '24px' }}>
            {note.titleDescription}
          </Typography>
        </Box>
      ))}
    </ProfilePageComponentWrapper>
  );
}
