export const getStringFromLocalStorage = (name) => {
  try {
    return localStorage.getItem(name);
  } catch (e) {
    console.error(e);
  }
};

export const setStringToLocalStorage = (name, value) => {
  try {
    return localStorage.setItem(name, value);
  } catch (e) {
    console.error(e);
  }
};

export const removeFromLocalStorage = (name) => {
  try {
    return localStorage.removeItem(name);
  } catch (e) {
    console.error(e);
  }
};

export const getObjectFromLocalStorage = (name) => {
  try {
    return JSON.parse(localStorage.getItem(name));
  } catch (e) {
    console.error(e);
  }
};

export const setObjectToLocalStorage = (name, value) => {
  try {
    return localStorage.setItem(name, JSON.stringify(value));
  } catch (e) {
    console.error(e);
  }
};
