import { Avatar, Box, Link, Typography } from '@mui/material';
import React from 'react';

import { ProfilePageComponentWrapper } from '../ProfilePageComponentWrapper';
import { Description, SubDescription, Subtitle, Title } from './Typography';
import { ProfileItemInfo } from './ProfileItemInfo';

export function ProfileReusableInfoCard({
  title,
  data,
}: {
  title: string;
  data: {
    label?: string;
    subLabel?: string;
    downLabel?: string;
    additionalLabel?: string;
    link?: string;
  }[];
}) {
  if (!data?.length) return null;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: 2, md: 3 } }}>
      <Title>{title}</Title>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {data?.map((item, index) => (
          <Box key={index}>
            {index > 0 && <Box sx={{ height: 1, flex: 1, my: 3, borderBottom: '1px solid rgba(0,0,0,0.05)' }} />}
            <ProfileItemInfo
              label={item.label}
              subLabel={item.subLabel}
              downLabel={item.downLabel}
              link={item.link}
              additionalLabel={item.additionalLabel}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
}
