const envConfigs = {
  production: {
    chat: {
      pdfSizing: 8, // in MB
      imgSizing: 8,
      contactEmail: 'chat@sonatico.com',
      savedMessagedId: 'SavedMessagesId',
    },
    firebase: {
      apiKey: 'AIzaSyAH5nd4Va2RLoU-sao8Mop5lfFhSFXRGow',
      authDomain: 'music-classes-877ed.firebaseapp.com',
      databaseURL: 'https://music-classes-877ed.firebaseio.com',
      projectId: 'music-classes-877ed',
      storageBucket: 'music-classes-877ed.appspot.com',
      messagingSenderId: '497506969991',
      appId: '1:497506969991:web:5f1897fa7620b37b9a0b22',
    },
    isProduction: true,
  },
  development: {
    chat: {
      pdfSizing: 8, // in MB
      imgSizing: 8,
      contactEmail: 'chat-dev@sonatico.com',
      savedMessagedId: 'SavedMessagesId',
    },
    firebase: {
      apiKey: 'AIzaSyCq0nCvU2SYOMG4HpGF-j-KOWLGKB98j7E',
      authDomain: 'sonatico-dfeb2.firebaseapp.com',
      projectId: 'sonatico-dfeb2',
      storageBucket: 'sonatico-dfeb2.appspot.com',
      messagingSenderId: '855808030898',
      appId: '1:855808030898:web:cb365332293c11e13371ab',
      measurementId: 'G-V8LM5WGF8E',
    },
    isDevelopment: true,
  },
};

export const config = envConfigs[process.env.NODE_ENV];

if (window) {
  (window as any).fbConfig = envConfigs[process.env.NODE_ENV];
}
