import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { themeVariables, globalStyles } from 'theme';

export default function CookieModal() {
  return (
    <CookieConsent
      location="none"
      buttonText="Accept"
      cookieName="useCookieAcceptance"
      style={{
        position: 'fixed',
        width: '90%',
        right: '5%',
        bottom: '60px',
        left: 'auto',
        top: 'auto',
        maxWidth: '430px',
        minWidth: '280px',
        height: 'auto',
        padding: '0',
        margin: 0,
        background: '#fefefe',
        fontSize: '13px',
        color: '#222',
        border: '1px solid #bbb',
        borderRadius: themeVariables.btnBorderRadius,
        boxShadow: '0 0 20px rgba(0,0,0,0.3)',
        alignItems: 'flex-start',
        justifyContent: 'center',
      }}
      buttonStyle={{
        background: globalStyles.gradientBg,
        color: '#fff',
        padding: '12px 22px',
        margin: '0 0 15px 0',
        textTransform: 'uppercase',
        fontSize: '14px',
        borderRadius: themeVariables.btnBorderRadius,
      }}
      expires={360}>
      We use essential cookies to make our site work. With your consent, we may also use non-essential cookies to
      improve user experience and analyze website traffic. By clicking “Accept,” you agree to our website's cookie use
      as described in our Cookie Policy.
    </CookieConsent>
  );
}
