import { Avatar, Box, Button, Pagination, PaginationItem, Typography } from '@mui/material';
import { ProfilePageComponentWrapper } from '../ProfilePageComponentWrapper';
import React, { useEffect, useState } from 'react';
import { Description, Subtitle, Title } from './Typography';
import { CommentModal } from './CommentModal';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import StarIcon from '@mui/icons-material/Star';
import { format } from 'date-fns';

export function ProfileReviews({ user, currentUser }) {
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [comments, setComments] = useState(user?.getData().comments || []);
  const [currentComments, setCurrentComments] = useState([]);

  const commentsPerPage = 2;
  useEffect(() => {
    const indexOfLastComment = currentPage * commentsPerPage;
    const indexOfFirstComment = indexOfLastComment - commentsPerPage;
    setCurrentComments(comments?.slice(indexOfFirstComment, indexOfLastComment));
  }, [comments, currentPage]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <ProfilePageComponentWrapper>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: '16px', md: '24px' } }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Title>Reviews ({comments?.length})</Title>
          <Button
            variant="outlined"
            sx={{
              boxShadow: '0px 1px 2px 0px #1018280D',
              border: '1px solid #D0D5DD',
              color: '#101828',
              padding: '8px',
              borderRadius: '8px',
              fontSize: { xs: '12px' },
              backgroundColor: '#fff',
            }}
            onClick={handleClickOpen}>
            Leave a comment
          </Button>
        </Box>
        <Box sx={{ marginBottom: '16px', display: 'flex', flexDirection: 'column', gap: '16px' }}>
          {currentComments.length === 0 && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography
                sx={{ fontSize: '14px', fontWeight: 600, color: '#8A94A6', textAlign: 'center', margin: '60px 0' }}>
                This teacher has no reviews yet. If you want to leave a review, click the "Leave a comment" button
              </Typography>
            </Box>
          )}
          {currentComments?.map((item) => (
            <ReviewsItem
              photo={item?.from?.photo}
              name={item?.from?.name}
              description={item?.comment}
              date={format(new Date(item?.date?.toDate()), 'MMMM yyyy')}
            />
          ))}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {comments?.length > commentsPerPage ? (
          <Pagination
            count={Math.ceil(comments?.length / commentsPerPage)}
            page={currentPage}
            onChange={(event, value) => setCurrentPage(value)}
            renderItem={(item) => (
              <PaginationItem slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }} {...item} />
            )}
          />
        ) : null}
      </Box>
      <CommentModal
        open={open}
        setOpen={setOpen}
        user={user}
        currentUser={currentUser}
        comments={comments}
        setComments={setComments}></CommentModal>
    </ProfilePageComponentWrapper>
  );
}

export function ReviewsItem({ photo, name, description, date }) {
  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        border: ' 1px solid #EAECF5',
        borderRadius: '8px',
        padding: '16px',
        display: 'flex',
        gap: '12px',
        position: 'relative',
      }}>
      <Avatar src={photo} sx={{ width: { xs: '32px', md: '64px' }, height: { xs: '32px', md: '64px' } }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Description description={name} />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              position: 'absolute',
              top: '16px',
              right: '16px',
            }}>
            <SingleStarRating rating={5}></SingleStarRating>
            <Typography sx={{ color: '#F6C430', fontSize: '16px', fontWeight: 600 }}>5.0</Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Typography sx={{ color: '#667085', fontWeight: 500, fontSize: '14px' }}>Basic drum course</Typography>
          <Typography sx={{ color: '#101828', fontWeight: 500, fontSize: '14px' }}>{description}</Typography>
          <Typography sx={{ color: '#667085', fontWeight: 500, fontSize: '14px' }}>{date}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

function SingleStarRating({ rating }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <StarIcon
        style={{ color: rating >= 2 ? '#F6C430' : 'rgba(0, 0, 0, 0.26)' }}
        sx={{ width: '20px', height: '20px' }}
      />
    </Box>
  );
}
