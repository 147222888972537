import LoadingIndicator from 'components/LoadingIndicator';
import { authAPI } from 'firebase-db';
import { createUserIfNew } from 'firebase-db/UserService';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useUser } from './useUser';

const authContext = createContext(undefined);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const provider = useProvideAuth();
  if (provider.user === null) return <LoadingIndicator />;

  return <authContext.Provider value={provider}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => useContext(authContext);

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  // const [user, setUser] = useState(null);
  const user = useUser();
  const [authLoading, setAuthLoading] = useState(true);

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = async (email: string, password: string) => {
    try {
      await authAPI.doSignInWithEmailAndPassword(email, password);
    } catch (e) {
      throw new Error(e);
    }
  };

  const signInWithGoogle = () =>
    authAPI.doSignInWithGoogle().catch((e) => {
      user.setUser(false);
      window.location.reload();
      throw new Error(e);
    });

  const signInWithFacebook = () => authAPI.doSignInWithFacebook();

  const signup = async (email: string, password: string) => {
    try {
      await authAPI.doCreateUserWithEmailAndPassword(email, password);
    } catch (e) {
      throw new Error(e);
    }
  };

  const signout = () =>
    authAPI.doSignOut().then(() => {
      user.setUser(false);
    });

  const sendPasswordResetEmail = (email: string) => authAPI.doPasswordReset(email).then(() => true);

  const confirmPasswordReset = (code: string, password: string) =>
    authAPI.doConfirmPasswordReset(code, password).then(() => true);

  const getAuthUserId = () => user && user.id;
  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = authAPI.onAuthStateChangedWrapper(async (userAuth: any) => {
      try {
        if (userAuth) {
          const { data: fbUser, error } = await createUserIfNew(userAuth);

          if (error) {
            console.error('Error creating/getting user:', error);
            user.setUser(false);
          }
          if (fbUser?.id) {
            await user.getUser(fbUser.id);
          } else {
            user.setUser(false);
          }
        } else {
          user.setUser(false);
        }
      } catch (error) {
        console.error('Unexpected error in auth state change:', error);
        user.setUser(false);
      } finally {
        setAuthLoading(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Return the user object and auth methods
  return {
    user,
    authLoading,
    getAuthUserId,
    signin,
    signInWithGoogle,
    signInWithFacebook,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
  };
}
