import { activeSignalReducer } from './reducers/participantSignalReducer';
import { createSelector } from 'reselect';

export const selectIsMenuVisible = createSelector(
  (state: any) => state.uiReducer,
  (uiReducer) => uiReducer.isMenuVisible
);

export const selectSendedSignal = createSelector(
  (state: any) => state.signalReducer,
  (signalReducer) => signalReducer.sendedSignal
);

export const selectActiveSignals = createSelector(
  (state: any) => state.activeSignalReducer,
  (activeSignalReducer) => activeSignalReducer
);

export const selectReceivedSignal = createSelector(
  (state: any) => state.signalReducer,
  (signalReducer) => signalReducer.receivedSignal
);

export const selectIsFullscreen = createSelector(
  (state: any) => state.uiReducer,
  (uiReducer) => uiReducer.isFullscreen
);

export const selectIsCallStarted = createSelector(
  (state: any) => state.uiReducer,
  (uiReducer) => uiReducer.isCallStarted
);
