import { createTheme } from '@mui/material/styles';

export const themeVariables = {
  btnBorderRadius: '8px',
  spacingSize: 8,
};

export const chatThemeVariables = {
  bgcolor: '#fcfcfd',
  border: '1px solid #EAECF5',
};

export const spacing = (multiplier: number): string => themeVariables.spacingSize * multiplier + 'px';

export const globalStyles = {
  primaryColor: '#3042ba',
  gradientBg: 'linear-gradient(135deg, #2949bb 0%, #3717a3 100%)',
  hoveredGradientBg: 'linear-gradient(135deg, #3860ec 0%, #4922cf 100%)',
  hoveredOutlineGradientBg: 'linear-gradient(135deg, #f7f9ff 0%, #e2e6ff 100%)',
  textColor: 'rgb(48, 58, 127)',
};

export const themeOptions: any = {
  typography: {
    fontFamily: `"Manrope", sans-serif`,
  },
  palette: {
    primary: {
      main: globalStyles.primaryColor,
    },
    default: {
      main: '#444444',
    },
  },
};

export const theme = createTheme(themeOptions);

export const getThemeColor = (color: string) => {
  return themeOptions.palette[color]?.main;
};
