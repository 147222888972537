import styled from 'styled-components';
import { spacing } from 'theme';

export const PageWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 ${spacing(2)};
`;
