import { Roles } from './Roles.Enum';
import { IUserContactData } from './User';

export class UserContact {
  public id = '';
  public displayName = '';
  public displaySurname = '';
  public email = '';
  public photoURL = '';
  public roles = [Roles.User];

  constructor({ id, displayName = '', displaySurname = '', email = '', photoURL = '', roles = [Roles.User] }) {
    this.id = id;
    this.displayName = displayName;
    this.displaySurname = displaySurname;
    this.email = email;
    this.photoURL = photoURL;
    this.roles = roles;
  }

  get fullName() {
    return `${this.displayName} ${this.displaySurname}`.trim();
  }

  getData(): IUserContactData {
    return {
      displayName: this.displayName,
      displaySurname: this.displaySurname,
      email: this.email,
      id: this.id,
      photoURL: this.photoURL,
      roles: this.roles,
    };
  }

  hasRole(role) {
    return this.roles.includes(role);
  }

  isTeacher() {
    return this.hasRole(Roles.Teacher);
  }

  isStudent() {
    return this.hasRole(Roles.Student);
  }
}
