const initState = { signal: null };

export const signalReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SEND_SIGNAL':
      return { ...state, sendedSignal: action.payload };
    case 'RECEIVE_SIGNAL':
      return { ...state, receivedSignal: action.payload };
    case 'REMOVE_SEND_SIGNAL':
      return { ...state, sendedSignal: null };
    case 'REMOVE_RECEIVE_SIGNAL':
      return { ...state, receivedSignal: null };
    default:
      return state;
  }
};
