import { Box, Button } from '@mui/material';
import { Title } from './Typography';
import React from 'react';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

export function ModalHeader({ handleClose, title }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderBottom: '1px solid #EAECF5',
        gap: '129px',
        padding: '16px 24px',
        marginBottom: '24px',
      }}>
      <Button
        onClick={handleClose}
        sx={{
          display: 'flex',
          gap: '8px',
          color: '#101828',
          padding: '8px 0',
        }}>
        <NavigateBeforeIcon sx={{ width: '24px', height: '24px' }}></NavigateBeforeIcon>
        Back
      </Button>
      <Title>{title}</Title>
    </Box>
  );
}
