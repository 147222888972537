import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';
import { config } from 'config';

const firebaseConfig = config.firebase; // Assuming you have your Firebase config defined properly in your 'config' file

const firebase = initializeApp(firebaseConfig);
const auth = getAuth(firebase);
const firestore = getFirestore(firebase);
const fns = getFunctions(firebase);
const storageRef = getStorage(firebase);
const fAnalytics = getAnalytics(firebase);

export { firebase, auth, firestore, fns, storageRef, fAnalytics };
