import React, { createContext, useContext, useState, useEffect } from 'react';
import { getUserById, saveUser, getUserRef } from 'firebase-db/UserService';
import { User } from 'models/User.Class';
import { IUserData } from 'models/User';
import { onSnapshot } from 'firebase/firestore';

const userContext = createContext(undefined);

// Provider component that wraps your app and makes user object ...
// ... available to any child component that calls useAuth().
export function ProvideUser({ children }) {
  const provider = useProvideUser();

  return <userContext.Provider value={provider}>{children}</userContext.Provider>;
}

export const useUser = () => useContext(userContext);

function useProvideUser() {
  const [user, setUser]: [User, any] = useState(null);
  const [userId, setUserId] = useState(null);

  const setId = (id) => {
    setUserId(id);
  };

  const getUser = (id) => {
    setUserId(id);
    return getUserById(id).then((userModel) => {
      setUser(userModel);
      return userModel;
    });
  };

  const saveData = (data: IUserData, options) => saveUser(data, options);

  useEffect(() => {
    if (!userId) return;
    const unsubscribe = onSnapshot(getUserRef(userId), (doc) => {
      if (doc.exists) {
        const newUser = new User({ id: doc.id, ...(doc.data() as User) });
        setUser(newUser);
      }
    });
    return unsubscribe;
  }, [userId]);

  return {
    model: user,
    setId,
    getUser,
    setUser,
    saveData,
  };
}
