export const socialLinkConstantObject = {
  't.me': 'Telegram',
  'wa.me': 'Whatsapp',
  'instagram.com': 'Instagram',
  'twitter.com': 'Twitter',
  'discord.com': 'Discord',
  'discord.gg': 'Discord',
  'facebook.com': 'Facebook',
  'linkedin.com': 'Linkedin',
  'github.com': 'Github',
  'medium.com': 'Medium',
  'tiktok.com': 'Tiktok',
  'youtube.com': 'Youtube',
  'reddit.com': 'Reddit',
  'pinterest.com': 'Pinterest',
  'snapchat.com': 'Snapchat',
  'twitch.tv': 'Twitch',
  'weibo.com': 'Weibo',
  'telegram.me': 'Telegram',
  'vimeo.com': 'Vimeo',
  'dribbble.com': 'Dribbble',
  'behance.net': 'Behance',
  'spotify.com': 'Spotify',
  'soundcloud.com': 'Soundcloud',
  'quora.com': 'Quora',
  'flickr.com': 'Flickr',
  'tumblr.com': 'Tumblr',
  'bilibili.com': 'Bilibili',
  'line.me': 'Line',
  'wechat.com': 'Wechat',
  'kakaotalk.com': 'KakaoTalk',
  'onlyfans.com': 'Onlyfans',
  'patreon.com': 'Patreon',
  'clubhouse.com': 'Clubhouse',
  'signal.org': 'Signal',
};

export const socialLinkConstantArray = Object.keys(socialLinkConstantObject);

export function checkSocialLink(link: string) {
  const linkKey = socialLinkConstantArray.find((item) => link.includes(item));
  if (linkKey) {
    return socialLinkConstantObject[linkKey];
  }
  return 'Other';
}
