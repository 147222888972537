import React from 'react';
import { Avatar } from '@mui/material';
import { FaceIcon } from 'components/icons';
import { spacing } from 'theme';

export function CustomAvatar({ url, size = 6, color = '#000' }: { url?: string; size?: number; color?: string }) {
  return url ? (
    <Avatar
      sx={{ height: spacing(size), width: spacing(size), border: '1px solid transparent' }}
      src={url?.toString()}
    />
  ) : (
    <Avatar
      sx={{
        backgroundColor: '#f1f1f1',
        border: '1px solid transparent',
        height: spacing(size),
        width: spacing(size),
      }}>
      <FaceIcon sx={{ color, height: spacing(size), width: spacing(size) }} />
    </Avatar>
  );
}
