import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import Button, { ButtonProps } from '@mui/material/Button';
import { globalStyles, themeVariables } from 'theme';

const breathingAnimation = keyframes`
  0% {
    opacity: 1;
  }
  
  40% {
    opacity: 0.7;
  }
  
  80% {
    opacity: 1;
  }
`;

type Variant = 'contained' | 'text' | 'outlined';

const ButtonBasic = ({
  children,
  minWidth,
  style,
  component,
  to = null,
  color = 'primary',
  variant = 'contained',
  sx,
  ...props
}: ButtonProps & {
  children?: React.ReactChild | React.ReactChild[];
  variant?: Variant;
  minWidth?: boolean | number;
  style?: any;
  component?: any;
  to?: any;
}) => {
  const styles = {
    minWidth: !minWidth ? '' : Number(minWidth) > 1 ? minWidth + 'px' : '150px',
    borderRadius: themeVariables.btnBorderRadius,

    '&.MuiButton-containedPrimary:not(.Mui-disabled)': {
      background: globalStyles.gradientBg,
      color: 'white',
      ':hover': {
        background: globalStyles.hoveredGradientBg,
      },
    },
    '&.MuiButton-outlinedPrimary:not(.Mui-disabled)': {
      borderColor: globalStyles.gradientBg,

      color: globalStyles.gradientBg,
      ':hover': {
        background: globalStyles.hoveredOutlineGradientBg,
      },
    },

    ...style,
    ...sx,
  };

  return (
    <Button sx={styles} color={color} variant={variant} component={component} to={to} {...props}>
      {children}
    </Button>
  );
};

export const BreathingButton = styled(ButtonBasic)<{ blinking: boolean }>`
  ${({ blinking }) =>
    blinking &&
    css`
      animation: ${breathingAnimation} 1s linear infinite;
    `}
`;

export default ButtonBasic;
