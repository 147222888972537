import { IstudentPreferences } from 'models/User';
import React from 'react';
import { Description, Subtitle, Title } from './Typography';
import { Box } from '@mui/material';
import { ProfilePageComponentWrapper } from '../ProfilePageComponentWrapper';
import { musicalInstruments } from 'containers/SettingsPage/Teacher/instruments';

export function StudentSpecializedSection({ specializations }: { specializations: IstudentPreferences[] }) {
  return (
    <ProfilePageComponentWrapper>
      <Box sx={{ marginBottom: '24px' }}>
        <Title>Specialized</Title>
      </Box>
      {specializations?.map((spec, index) => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '24px',

              marginBottom: spec !== specializations[specializations?.length - 1] && '24px',
            }}>
            <StudentSpecializedDetails
              specializedDetailsTitle="Instrument"
              specializedDetailsDescription={musicalInstruments[spec?.name]}
            />
            <StudentSpecializedDetails specializedDetailsTitle="Level" specializedDetailsDescription={spec.level} />
            {spec !== specializations[specializations?.length - 1] && (
              <Box sx={{ height: '1px', width: '100%', backgroundColor: '#EAECF5' }} />
            )}
          </Box>
        );
      })}
    </ProfilePageComponentWrapper>
  );
}

function StudentSpecializedDetails({ specializedDetailsTitle, specializedDetailsDescription }) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Subtitle subtitle={specializedDetailsTitle}></Subtitle>
      <Description description={specializedDetailsDescription}></Description>
    </Box>
  );
}
