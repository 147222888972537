import { CircularProgress } from 'components/basic';
import React from 'react';

import Wrapper from './Wrapper';

const LoadingIndicator = () => (
  <Wrapper>
    <CircularProgress />
  </Wrapper>
);

export default LoadingIndicator;
