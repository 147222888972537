import { device } from 'components/variables';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as SonaticoLogo } from 'assets/Sonatico_logo.svg';
import { ReactComponent as SonaticoLogoSymbol } from 'assets/Sonatico_Symbol_Sergaster.svg';

import { useWindowSize } from 'hooks/useWindowSize';
import { globalStyles } from 'theme';

const LogoS = styled.img`
  height: 28px;
  margin-top: -5px;

  @media ${device.tablet} {
    height: 38px;
  }

  @media ${device.laptop} {
    height: 48px;
  }
`;

const LogoWrapper = styled.span`
  display: flex;
  position: relative;
  height: 32px;
  justify-content: center;
  align-items: center;
  min-width: 38px;

  @media ${device.tablet} {
    height: 40px;
    min-width: 110px;
  }

  svg {
    margin: 0 -8px;
    width: 100%;
    height: 100%;
    color: ${globalStyles.textColor};
  }
`;

export const Logo = (params: any) => {
  const size = useWindowSize();

  return (
    <LogoWrapper>
      {size.width >= 768 ? <SonaticoLogo /> : <SonaticoLogoSymbol />}

      {/* <LogoS src={size.width >= 768 ? SonaticoLogo : SonaticoLogoSymbol} alt="Logo"></LogoS> */}
    </LogoWrapper>
  );
};
