import { Typography } from '@mui/material';
import React from 'react';

export function Title({ children }: { children: React.ReactNode }) {
  return (
    <Typography sx={{ opacity: 0.5, textTransform: 'uppercase', fontSize: { xs: '16px', md: '18px' } }}>
      {children}
    </Typography>
  );
}

export function Subtitle({ subtitle }) {
  return <Typography sx={{ color: '#667085', fontSize: '14px', fontWeight: 700 }}>{subtitle}</Typography>;
}

export function SubDescription({ children }: { children: React.ReactNode }) {
  return <Typography sx={{ color: '#667085', fontSize: '14px', fontWeight: 500 }}>{children}</Typography>;
}

export function Description({ description }) {
  return <Typography sx={{ color: '#101828', fontSize: '16px', fontWeight: 600 }}>{description}</Typography>;
}
