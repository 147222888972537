import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { DatePicker as DatePickerMUI, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { themeVariables } from 'theme';

const breathingAnimation = keyframes`
  0% {
    opacity: 1;
  }
  
  40% {
    opacity: 0.7;
  }
  
  80% {
    opacity: 1;
  }
`;

type Variant = 'contained' | 'text' | 'outlined';

const DatePicker = ({
  children,
  minWidth,
  fullWidth,
  style,
  sx,
  ...props
}: DatePickerProps<Date> & {
  children?: React.ReactChild | React.ReactChild[];
  variant?: Variant;
  minWidth?: string | boolean;
  fullWidth?: boolean;
  style?: any;
  component?: any;
  to?: any;
}) => {
  const styles = {
    minWidth: !minWidth ? '' : Number(minWidth) > 1 ? minWidth : '150px',
    borderRadius: themeVariables.btnBorderRadius,
    width: fullWidth ? '100%' : '',
    '.MuiInputBase-root': {
      borderRadius: themeVariables.btnBorderRadius,
      '.MuiInputBase-input': {
        padding: '8.5px 14px',
      },
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d32f2f',
    },
    '.MuiButtonBase-root': {
      borderRadius: themeVariables.btnBorderRadius,
    },
    ...style,
    ...sx,
  };

  return <DatePickerMUI slotProps={{ textField: { size: 'small' } }} sx={styles} {...props} />;
};

export default DatePicker;
