import React from 'react';
import Switch from '@mui/material/Switch';

const SwitchBasic = (props) => {
  return (
    <Switch {...props} />
  );
};


export default SwitchBasic;
