import { Box, List, ListItem } from '@mui/material';
import React from 'react';
import { ProfilePageComponentWrapper } from '../ProfilePageComponentWrapper';
import { Description, Subtitle, Title } from './Typography';
import { IteacherPreferences } from 'models/User';
import { instrumentList, musicalInstruments } from 'containers/SettingsPage/Teacher/instruments';

interface SpecializedDetailsProps {
  specializedDetailsTitle: string;
  specializedDetailsDescription: string;
}

export function SpecializedSection({ specializations }: { specializations: IteacherPreferences[] }) {
  return (
    <ProfilePageComponentWrapper>
      <Title>Specialized</Title>
      <List sx={{ marginTop: { xs: '16px', md: '24px' } }}>
        {specializations?.map((spec, index) => {
          return (
            spec && (
              <ListItem
                key={index}
                sx={{
                  padding: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '12px',
                  alignItems: 'start',
                  paddingBottom: spec !== specializations[specializations?.length - 1] ? '24px' : '0',
                  paddingTop: spec !== specializations[0] ? '24px' : '0',
                  borderBottom: spec !== specializations[specializations?.length - 1] ? '1px solid #EAECF5' : 'none',
                }}>
                <SpecializedDetails
                  specializedDetailsTitle="Age Group"
                  specializedDetailsDescription={
                    Array.isArray(spec?.ageGroup) ? spec?.ageGroup?.join(', ') : spec?.ageGroup
                  }
                />
                <SpecializedDetails
                  specializedDetailsTitle="Instrument"
                  specializedDetailsDescription={musicalInstruments[spec?.name]}
                />
                <SpecializedDetails
                  specializedDetailsTitle="Levels"
                  specializedDetailsDescription={spec?.level.join(', ')}
                />
              </ListItem>
            )
          );
        })}
      </List>
    </ProfilePageComponentWrapper>
  );
}

export function SpecializedDetails({
  specializedDetailsTitle,
  specializedDetailsDescription,
}: SpecializedDetailsProps) {
  return (
    <Box display="flex" flexDirection="column" gap="4px">
      <Subtitle subtitle={specializedDetailsTitle}></Subtitle>
      <Description description={specializedDetailsDescription}></Description>
    </Box>
  );
}
